

import React, { useState } from 'react';
import AdmissionForm from '../Forms/AdmissionForm';
import GuestForm from '../Forms/GuestForm';
import SelectionForm from '../SelectionForm/SelectionForm';

const Book = () => {

    const [selectedForm, setSelectedForm] = useState(null);

    const handleSelectForm = (form) => {
        setSelectedForm(form);
    };

    return (
        <div className="container mx-auto p-4">
            <div className="space-y-6">
                <div className="flex items-center space-x-4">
                    <div className="flex flex-col">
                        <label className="font-bold text-lg text-green-500">
                            Select As A <span className="text-orange-500">Guest Border</span>
                        </label>
                        <div className="flex items-center mt-2">
                            <input
                                type="checkbox"
                                id="guestForm"
                                checked={selectedForm === 'guest'}
                                className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
                                onChange={() => handleSelectForm('guest')}
                            />
                            <label htmlFor="guestForm" className="text-gray-700">Guest Form</label>
                        </div>
                    </div>

                    <div className="flex flex-col">
                        <label className="font-bold text-lg text-green-500">
                            Select As a <span className="text-orange-500">Permanent Border</span>
                        </label>
                        <div className="flex items-center mt-2">
                            <input
                                type="checkbox"
                                id="admissionForm"
                                checked={selectedForm === 'admission'}
                                className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
                                onChange={() => handleSelectForm('admission')}
                            />
                            <label htmlFor="admissionForm" className="text-gray-700">Admission Form</label>
                        </div>
                    </div>
                </div>
            </div>

            {selectedForm === 'guest' && <GuestForm />}
            {selectedForm === 'admission' && <AdmissionForm />}
        </div>
    );
};

export default Book;





















// import React, { useState } from 'react';
// import AdmissionForm from '../Forms/AdmissionForm';
// import GuestForm from '../Forms/GuestForm';
// import SelectionForm from '../SelectionForm/SelectionForm';

// const Book = () => {

//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [selectedForm, setSelectedForm] = useState(null);

//     const handleOpenDialog = () => {
//         setIsDialogOpen(true);
//     };

//     const handleCloseDialog = () => {
//         setIsDialogOpen(false);
//     };

//     const handleSelectForm = (form) => {
//         setSelectedForm(form);
//         setIsDialogOpen(false);
//     };

//     return (
//         // <div>
//         //     <AdmissionForm />
//         //     <br />
//         //     {/* <GuestForm /> */}
//         //     <br />
//         // </div>

//         <div className="container mx-auto p-4 ">
        
            
//             <div className="flex items-center space-x-4">
            
//                 <div className="flex items-center">
                    
//                     <input
//                         type="checkbox"
//                         id="guestForm"
//                         className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
//                         onChange={() => handleSelectForm('guest')}
//                     />
//                     <label htmlFor="guestForm" className="text-gray-700">Guest Form</label>
//                 </div>

//                 <div className="flex items-center">
//                     <input
//                         type="checkbox"
//                         id="admissionForm"
//                         className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
//                         onChange={() => handleSelectForm('admission')}
//                     />
//                     <label htmlFor="admissionForm" className="text-gray-700">Admission Form</label>
//                 </div>
//             </div>





//             <SelectionForm
//                 isOpen={isDialogOpen}
//                 onClose={handleCloseDialog}
//                 onSelect={handleSelectForm}
//             />

//             {selectedForm === 'guest' && <GuestForm />}
//             {selectedForm === 'admission' && <AdmissionForm />}
//         </div>

//     );
// };

// export default Book;



// 2nd update

// import React, { useState } from 'react';
// import AdmissionForm from '../Forms/AdmissionForm';
// import GuestForm from '../Forms/GuestForm';
// import SelectionForm from '../SelectionForm/SelectionForm';

// const Book = () => {

//     const [isDialogOpen, setIsDialogOpen] = useState(false);
//     const [selectedForm, setSelectedForm] = useState(null);

//     const handleOpenDialog = () => {
//         setIsDialogOpen(true);
//     };

//     const handleCloseDialog = () => {
//         setIsDialogOpen(false);
//     };

//     const handleSelectForm = (form) => {
//         setSelectedForm(form);
//         setIsDialogOpen(false);
//     };

//     return (
//         <div className="container mx-auto p-4">
//             <div className="space-y-6">
//                 <div className="flex items-center space-x-4">
//                     <div className="flex flex-col">
//                         <label className="font-bold text-lg text-green-500">
//                             Select As A <span className="text-orange-500">Guest Border</span>
//                         </label>
//                         <div className="flex items-center mt-2">
//                             <input
//                                 type="checkbox"
//                                 id="guestForm"
//                                 className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
//                                 onChange={() => handleSelectForm('guest')}
//                             />
//                             <label htmlFor="guestForm" className="text-gray-700">Guest Form</label>
//                         </div>
//                     </div>

//                     <div className="flex flex-col">
//                         <label className="font-bold text-lg text-green-500">
//                             Select As a <span className="text-orange-500">Permanent Border</span>
//                         </label>
//                         <div className="flex items-center mt-2">
//                             <input
//                                 type="checkbox"
//                                 id="admissionForm"
//                                 className="mr-2 h-4 w-4 text-purple-500 border-gray-300 rounded focus:ring-purple-500"
//                                 onChange={() => handleSelectForm('admission')}
//                             />
//                             <label htmlFor="admissionForm" className="text-gray-700">Admission Form</label>
//                         </div>
//                     </div>
//                 </div>
//             </div>

//             <SelectionForm
//                 isOpen={isDialogOpen}
//                 onClose={handleCloseDialog}
//                 onSelect={handleSelectForm}
//             />

//             {selectedForm === 'guest' && <GuestForm />}
//             {selectedForm === 'admission' && <AdmissionForm />}
//         </div>
//     );
// };

// export default Book;




// 3rd update

