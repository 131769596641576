
import logoImage from "../../assets/younic-logo.jpg";
import React, { useState } from 'react';

const AdmissionForm = () => {
    const [imageFile, setImageFile] = useState(null);
    const [roomType, setRoomType] = useState('');

    const handleImageUpload = (event) => {
        const newImage = event.target.files[0];
        // Basic image validation (optional)
        if (!newImage || !newImage.type.match('image/.*')) {
            alert('Please select a valid image file.');
            return;
        }
        setImageFile(newImage);
    };

    const handleRoomTypeChange = (event) => {
        setRoomType(event.target.value);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-md">
            <div className="flex justify-between items-center mb-6">
                <img src={logoImage} className="w-[180px] h-[120px]" alt="Younic Logo" />
                <h2 className="text-5xl font-bold text-green-500 text-center">Younic Home</h2>
                <div className="flex flex-col items-center">
                    <img
                        id="userImage"
                        src={imageFile ? URL.createObjectURL(imageFile) : ''}
                        alt="Click to upload an image"
                        className="w-[120px] h-[120px] border border-green-500 cursor-pointer"
                        onClick={() => document.getElementById('imageInput').click()}
                    />
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleImageUpload}
                    />
                </div>
            </div>

            <div className="flex justify-between items-center mb-6">
                <h2 className="bg-green-500 text-white p-2 rounded">Information</h2>
                <h3 className="bg-green-500 text-white p-2 rounded mx-auto inline-block">Admission Form</h3>
            </div>

            <form>
                <div className="mb-4">
                    <label className="block text-gray-700">Full Name</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">NID/Birth Certificate Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Passport Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Nationality</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Religion</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Address</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Email</label>
                        <input type="email" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    

                        
                    <div className="flex-1">
                        <label className="block text-gray-700">Blood Group</label>
                        <select className="w-full p-2 border border-gray-300 rounded focus:outline-none">
                            <option value="">Select Blood Group</option>
                            <option value="A+">A+</option>
                            <option value="A-">A-</option>
                            <option value="B+">B+</option>
                            <option value="B-">B-</option>
                            <option value="AB+">AB+</option>
                            <option value="AB-">AB-</option>
                            <option value="O+">O+</option>
                            <option value="O-">O-</option>
                        </select>
                        </div>
                       
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Father's Name</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Father's Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Mother's Name</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Mother's Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Educational Institute Name</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Educational Institute ID Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Department Of Educational Institute</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Local Guardian's Name</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Reason For Staying Residential Dormitory</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Are you associated with student of Younic Home ?</label>
                    <div className="flex items-center">
                        <input type="radio" name="coaching" value="yes" className="mr-2" /> Yes
                        <input type="radio" name="coaching" value="no" className="ml-4 mr-2" /> No
                    </div>
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Room Type</label>
                    <select
                        value={roomType}
                        onChange={handleRoomTypeChange}
                        className="w-full p-2 border border-gray-300 rounded"
                    >
                        <option value="">Select a room type</option>
                        <option value="single">Single</option>
                        <option value="double">Double</option>
                        <option value="suite">Suite</option>
                    </select>
                </div>

                <div className="mb-6">
                    <div className="flex justify-center">
                        <h4 className="font-bold mb-2 text-center bg-green-500 text-white inline-block px-2 py-1 rounded">Rules and Regulations</h4>
                    </div>

                    <ol className="list-decimal list-inside text-sm text-gray-700">
                        <li>Rent to be paid between 01st to 05th of every month. From 06th, a fine of tk. 50 per day will be levied. </li>
                        <li>Students of institutions affiliated to Unique Homes have to enter the hostel by 07:00 PM and others have to enter the hostel by 10:00 PM. In case of delay due to any reason, application should be made to the authorities.</li>
                        <li>Conversing with any male friend while standing in front of the hostel is not allowed. Riding rickshaws/bikes with them is prohibited.</li>
                        <li>Playing loud music and talking loudly are not permitted. Room lights should not be switched on after 11 pm. Accessing other rooms is not allowed.</li>
                        <li>Consumption of drugs or engaging in activities detrimental to the nation within the hostel premises is strictly prohibited. Bringing in prohibited items is also not allowed, and any such actions will result in legal consequences.</li>
                        <li>Upon admission to the hostel, students are required to pay the admission fee and one month's rent in advance.</li>
                        <li>Should a resident wish to leave the hostel, a notice period of at least two months is mandatory. Failure to comply will result in the payment of dues for the subsequent month.</li>
                        <li>Each hostel resident must provide photocopies of their university or company ID, as well as copies of their parents' national ID cards, along with their own ID or birth certificate, and two passport-sized photographs. If the resident is of a different nationality, a copy of their passport must be provided.</li>
                        <li>If a former resident wishes to reapply for accommodation after vacating their seat, they must complete all necessary hostel procedures again.</li>
                        <li>Residents are encouraged to report any issues or concerns to the hostel staff during office hours.</li>
                        <li>Damage to hostel property will incur repair costs to be borne by the responsible individual.</li>
                        <li>Theft of any kind will result in immediate disciplinary action and restitution.</li>
                        <li>Residents must sign in and out when entering and leaving the premises.</li>
                        <li>Residents are expected to maintain a standard of appropriate attire at all times.</li>
                    </ol>
                </div>

                <button type="submit" className="w-full bg-orange-400 hover:bg-green-400 text-white py-2 rounded">Submit</button>
            </form>
        </div>
    );
};

export default AdmissionForm;









// {/* <div className="flex-1">
//                         <label className="block text-gray-700">Blood Group</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
//                     </div> */}


























//this more older code
// import React, { useState } from 'react';
// import logoImage from "../../assets/younic-logo.jpg";

// const AdmissionForm = () => {

//     const [imageFile, setImageFile] = useState(null);

//     const handleImageUpload = (event) => {
//         const newImage = event.target.files[0];
//         // Basic image validation (optional)
//         if (!newImage || !newImage.type.match('image/.*')) {
//             alert('Please select a valid image file.');
//             return;
//         }
//         setImageFile(newImage);
//     };

//     return (
//         <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-md">
//             <div className="flex justify-between items-center mb-6">
//                 <img src={logoImage} className="w-[180px] h-[120px]" alt="Younic Logo" />
//                 <h2 className="text-5xl font-bold text-green-500 text-center">Younic Home</h2>
//                 <div className="flex flex-col items-center">
//                     <img
//                         id="userImage"
//                         src={imageFile ? URL.createObjectURL(imageFile) : ''}
//                         alt="Click to upload an image"
//                         className="w-[120px] h-[120px] border border-green-500 cursor-pointer"
//                         onClick={() => document.getElementById('imageInput').click()}
//                     />
//                     <input
//                         id="imageInput"
//                         type="file"
//                         accept="image/*"
//                         hidden
//                         onChange={handleImageUpload}
//                     />
//                 </div>
//             </div>

//             <div className="flex justify-between items-center mb-6">
//                 <h2 className="bg-green-500 text-white p-2 rounded">Information</h2>
//                 <h3 className="bg-green-500 text-white p-2 rounded mx-auto inline-block">Admission Form</h3>
//             </div>

//             <form>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Full Name</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>

//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Mobile Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />

//                     <label className="block text-gray-700">National ID/Birth Certificate Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />

//                 </div> */}

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Mobile Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">NID/Birth Certificate Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>

//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">National ID/Birth Certificate Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Passport Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Nationality</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Religion</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>
//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Passport Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Nationality</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Religion</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Address</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Email</label>
//                         <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Blood Group</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>
//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Email</label>
//                     <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Blood Group</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Father Name</label>
//                         <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Father Mobile Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>

//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Father's Name</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Father's Mobile Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Mother's Name</label>
//                         <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Mother's Mobile Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>

//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Mother's Name</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Mother's Mobile Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}

//                 <div className="mb-4">
//                     <label className="block text-gray-700">Educational Institute Name</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Educational Institute ID Number</label>
//                         <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Department Of Educational Institute</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>

//                 <div className="mb-4 flex gap-4">
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Local Guardian's Name</label>
//                         <input type="email" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                     <div className="flex-1">
//                         <label className="block text-gray-700">Mobile Number</label>
//                         <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                     </div>
//                 </div>

//                 {/* <div className="mb-4">
//                     <label className="block text-gray-700">Local Guardian's Name</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>
//                 <div className="mb-4">
//                     <label className="block text-gray-700">Guardian's Mobile Number</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div> */}

//                 <div className="mb-4">
//                     <label className="block text-gray-700">Reason For Staying Residential Dormitory</label>
//                     <input type="text" className="w-full p-2 border border-gray-300 rounded" />
//                 </div>

//                 <div className="mb-4">
//                     <label className="block text-gray-700">Are you associated with student of Younic Home ?</label>
//                     <div className="flex items-center">
//                         <input type="radio" name="coaching" value="yes" className="mr-2" /> Yes
//                         <input type="radio" name="coaching" value="no" className="ml-4 mr-2" /> No
//                     </div>
//                 </div>


//                 <div className="mb-6">
//                     <div className="flex justify-center">
//                         <h4 className="font-bold mb-2 text-center bg-green-500 text-white inline-block px-2 py-1 rounded">Rules and Regulations</h4>
//                     </div>

//                     <ol className="list-decimal list-inside text-sm text-gray-700">

//                         <li>Rent to be paid between 01st to 05th of every month. From 06th, a fine of tk. 50 per day will be levied. </li>
//                         <li>Students of institutions affiliated to Unique Homes have to enter the hostel by 07:00 PM and others have to enter the hostel by 10:00 PM. In case of delay due to any reason, application should be made to the authorities.</li>
//                         <li>Conversing with any male friend while standing in front of the hostel is not allowed. Riding rickshaws/bikes with them is prohibited.</li>
//                         <li>Playing loud music and talking loudly are not permitted. Room lights should not be switched on after 11 pm. Accessing other rooms is not allowed.</li>
//                         <li>Consumption of drugs or engaging in activities detrimental to the nation within the hostel premises is strictly prohibited. Bringing in prohibited items is also not allowed, and any such actions will result in legal consequences.</li>
//                         <li>Upon admission to the hostel, students are required to pay the admission fee and one month's rent in advance.</li>
//                         <li>Should a resident wish to leave the hostel, a notice period of at least two months is mandatory. Failure to comply will result in the payment of dues for the subsequent month.</li>
//                         <li>Each hostel resident must provide photocopies of their university or company ID, as well as copies of their parents' national ID cards, along with their own ID or birth certificate, and two passport-sized photographs. If the resident is of a different nationality, a copy of their passport must be provided.</li>
//                         <li>If a former resident wishes to reapply for accommodation after vacating their seat, they must complete all necessary hostel procedures again.</li>
//                         <li>Residents are encouraged to report any issues or concerns to the hostel staff during office hours.</li>
//                         <li>Damage to hostel property will incur repair costs to be borne by the responsible individual.</li>
//                         <li>Theft of any kind will result in immediate disciplinary action and restitution.</li>
//                         <li>Residents must sign in and out when entering and leaving the premises.</li>
//                         <li>Residents are expected to maintain a standard of appropriate attire at all times.</li>

//                     </ol>
//                 </div>


//                 <button type="submit" className="w-full bg-orange-400 hover:bg-green-400 text-white py-2 rounded">Submit</button>
//             </form>
//         </div>
//     );
// };

// export default AdmissionForm;