
import React from 'react';
import { styled } from '@mui/system';
import Grid from '@mui/material/Grid';
import Marquee from 'react-fast-marquee';
// import AshuliaImg1 from "../../assets/image/Younic-Dormitory-Rooms-img/A-album-img1.jpg";
// import AshuliaImg2 from "../../assets/image/Younic-Dormitory-Rooms-img/A-album-img2.jpg";
import AshuliaImg3 from "../../assets/image/Younic-Dormitory-Rooms-img/1111111.jpg";
import AshuliaImg4 from "../../assets/image/Younic-Dormitory-Rooms-img/U-album-img4.jpg";
import AshuliaImg5 from "../../assets/image/Younic-Dormitory-Rooms-img/U-album-img7.jpg";
import AshuliaImg6 from "../../assets/image/Younic-Dormitory-Rooms-img/D-album-img8.jpg";

const images = [
    // { img: AshuliaImg1, title: 'Bunkar bed', description: 'This is our Bunkar bed' },
    // { img: AshuliaImg2, title: 'Reading table', description: 'Reading Table for our hostel border' },
    { img: AshuliaImg3, title: 'Single Room', description: 'Single Room With Double Bed' },
    { img: AshuliaImg4, title: 'Single Room', description: 'Single Room With Double Bed' },
    { img: AshuliaImg5, title: 'Single Room', description: 'Single Room With Double Bed' },
    { img: AshuliaImg6, title: 'Single Room', description: 'Single Room With Double Bed' },
    { img: AshuliaImg3, title: 'Single Room', description: 'Single Room With Double Bed' },
    { img: AshuliaImg5, title: 'Single Room', description: 'Single Room With Double Bed' }
];

const SlideContainer = styled('div')(({ theme }) => ({
    height: '100%',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    textAlign: 'center',
    padding: theme.spacing(2),
}));

const StyledImage = styled('img')(({ theme }) => ({
    maxWidth: '250px',
    maxHeight: '250px',
    borderRadius: theme.shape.borderRadius,
}));

const YounicRoomSlider = () => {
    return (
               
            //  className="border-4 border-green-500 border-double p-4 mb-4 "
        <div className=" p-4 mb-4 ">
            <h1 className="text-2xl font-bold text-center mb-5">Younic Dormitory Rooms</h1>
            <Marquee gradient={false} speed={50}>
                <Grid container spacing={3} justifyContent="center">
                    {images.map((image, index) => (
                        <Grid item key={index}>
                            <SlideContainer>
                                <StyledImage src={image.img} alt={image.title} />
                                <h3 className="text-center">{image.title}</h3>
                                <p className="text-center">{image.description}</p>
                            </SlideContainer>
                        </Grid>
                    ))}
                </Grid>
            </Marquee>
        </div>
    );
};

export default YounicRoomSlider;


