import React from "react";

const SearchBar = () => {
    return (
        <div className="mb-10 text-center mt-10">
            <h1 className="text-2xl md:text-3xl font-bold mb-2">
                Find the perfect Dormitory on Younic Dorms X
            </h1>
            <p className="text-gray-600 mb-4">
                From budget-friendly dormitories to luxury rooms and everything in between
            </p>
            <div className="flex flex-col md:flex-row items-center justify-between bg-white shadow-md rounded-lg p-4 max-w-5xl mx-auto space-y-3 md:space-y-0">
                <input
                    type="text"
                    placeholder="Where are you going?"
                    className="p-3 w-full md:w-1/3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200 ease-in-out"
                />
                <input
                    type="text"
                    placeholder="Check-in - Check-out"
                    className="p-3 w-full md:w-1/3 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200 ease-in-out mx-0 md:mx-2"
                />
                <input
                    type="text"
                    placeholder="1 adult - 1 room"
                    className="p-3 w-full md:w-1/4 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-orange-400 transition duration-200 ease-in-out"
                />
                <button className="bg-orange-400 hover:bg-orange-500 text-white p-3 rounded-md w-full md:w-auto transition duration-200 ease-in-out mt-2 md:mt-0 ml-0 md:ml-2">
                    Search
                </button>
            </div>
        </div>
    );
};

export default SearchBar;
