
import { useEffect, useState } from "react";
import Sliderimg1 from "../../assets/image/Simg1.jpg";
import Sliderimg2 from "../../assets/image/Simg2.png";
import Sliderimg3 from "../../assets/image/Simg3.jpg";
import Sliderimg4 from "../../assets/image/Simg4.jpg";

import { Link } from 'react-router-dom';

const Slider = () => {
  const [activeIndex, setActiveIndex] = useState(0);
  const slides = [Sliderimg1, Sliderimg2, Sliderimg3, Sliderimg4];

  useEffect(() => {
    const interval = setInterval(() => {
      setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
    }, 5000);

    return () => clearInterval(interval);
  }, [slides.length]);

  return (
    <div className="p-4 mb-4 border-4 border-t-orange-500 border-b-orange-500 border-l-green-500 border-r-green-500 duration-500">
      <div className="carousel w-full rounded">
        {slides.map((slide, index) => (
          <div
            key={index}
            className={`carousel-item relative w-full h-3/4 ${index === activeIndex ? "active" : "hidden"
              }`}
          >

            {/* BookNow two buttons code start here */}
            <div className="relative">
              {/* <img src={slide} className="w-full h-3/4" /> */}
              <img src={slide} className="w-full h-18" />
              {/* className=" absolute right-80 top-2  " */}
                  <div className="absolute top-2 left-1/2 transform -translate-x-1/2 -translate-y-1 justify-center items-center">

                        <button className="p-1 rounded bg-orange-400 hover:bg-green-400 m-2">
                          <Link to="/rooms">  Book As Permanent </Link> 
                        </button>

                        <button className="p-1 rounded bg-orange-400 hover:bg-green-400">
                          <Link to="/branch-wise-packages"> Book As Guest </Link>
                        </button>

                  </div>
            </div>

            

            <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">


              <button
                onClick={() =>
                  setActiveIndex((prevIndex) =>
                    prevIndex === 0 ? slides.length - 1 : prevIndex - 1
                  )
                }
                className="btn btn-circle"
              >
                ❮
              </button>
              <button
                onClick={() =>
                  setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length)
                }
                className="btn btn-circle"
              >
                ❯
              </button>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default Slider;












//last updated
// import { useEffect, useState } from "react";
// import Sliderimg1 from "../../assets/image/Simg1.jpg";
// import Sliderimg2 from "../../assets/image/Simg2.png";
// import Sliderimg3 from "../../assets/image/Simg3.jpg";
// import Sliderimg4 from "../../assets/image/Simg4.jpg";

// const Slider = () => {
//   const [activeIndex, setActiveIndex] = useState(0);
//   const slides = [Sliderimg1, Sliderimg2, Sliderimg3, Sliderimg4];

//   useEffect(() => {
//     const interval = setInterval(() => {
//       setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length);
//     }, 3000);

//     return () => clearInterval(interval);
//   }, [slides.length]);

//   return (
//     <div className="p-4 mb-4 border-4 border-t-orange-500 border-b-orange-500 border-l-green-500 border-r-green-500 duration-500">
//       <div className="carousel w-full rounded">
//         {slides.map((slide, index) => (
//           <div
//             key={index}
//             className={`carousel-item relative w-full h-3/4 ${
//               index === activeIndex ? "active" : "hidden"
//             }`}
//           >
//             <img src={slide} className="w-full h-3/4" />

//             <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//               <button
//                 onClick={() =>
//                   setActiveIndex((prevIndex) =>
//                     prevIndex === 0 ? slides.length - 1 : prevIndex - 1
//                   )
//                 }
//                 className="btn btn-circle"
//               >
//                 ❮
//               </button>
//               <button
//                 onClick={() =>
//                   setActiveIndex((prevIndex) => (prevIndex + 1) % slides.length)
//                 }
//                 className="btn btn-circle"
//               >
//                 ❯
//               </button>
//             </div>
//           </div>
//         ))}
//       </div>
//     </div>
//   );
// };

// export default Slider;


























// import Sliderimg1 from "../../assets/image/Simg1.jpg";
// import Sliderimg2 from "../../assets/image/Simg2.png";
// import Sliderimg3 from "../../assets/image/Simg3.jpg";
// // import Sliderimg4 from "../../assets/image/head-office.jpg";
// import Sliderimg4 from "../../assets/image/Simg4.jpg";

// const Slider = () => {
//     return (
//         <div className="p-4 mb-4  border-4  border-t-orange-500 border-b-orange-500 border-l-green-500 border-r-green-500 duration-500 ">
//                     <div className="carousel w-full rounded  ">
//                 <div id="slide1" className="carousel-item relative w-full h-3/4 ">
//                   <img src={Sliderimg1} className="w-full h-3/4 " />
//                   <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//                     <a href="#slide4" className="btn btn-circle">❮</a>
//                     <a href="#slide2" className="btn btn-circle">❯</a>
//                   </div>
//                 </div>
//                 <div id="slide2" className="carousel-item relative w-full h-3/4">
//                   <img src={Sliderimg2} className="w-full h-3/4 " />
//                   <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//                     <a href="#slide1" className="btn btn-circle">❮</a>
//                     <a href="#slide3" className="btn btn-circle">❯</a>
//                   </div>
//                 </div>
//                 <div id="slide3" className="carousel-item relative w-full h-3/4">
//                   <img src={Sliderimg3} className="w-full h-3/4" />
//                   <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//                     <a href="#slide2" className="btn btn-circle">❮</a>
//                     <a href="#slide4" className="btn btn-circle">❯</a>
//                   </div>
//                 </div>
//                 {/* <div id="slide4" className="carousel-item relative w-full">
//                   <img src={Sliderimg4} className="w-full" />
//                   <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//                     <a href="#slide3" className="btn btn-circle">❮</a>
//                     <a href="#slide1" className="btn btn-circle">❯</a>
//                   </div>
//                 </div> */}

//                 <div id="slide4" className="carousel-item relative w-full h-3/4">
//                   <img src={Sliderimg4} className="w-full h-3/4" />
//                   <div className="absolute flex justify-between transform -translate-y-1/2 left-5 right-5 top-1/2">
//                     <a href="#slide3" className="btn btn-circle">❮</a>
//                     <a href="#slide1" className="btn btn-circle">❯</a>
//                   </div>
//                 </div>
//               </div>
//         </div>
//     );
// };

// export default Slider;