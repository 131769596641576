
import * as React from "react";
import * as ReactDOM from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import "./index.css";
import Root from "./Components/Root/Root";
import Home from "./Components/Home/Home";
import Rooms from "./Components/Rooms/Rooms";
import Gallery from "./Components/Gallery/Gallery";
import About from "./Components/About/About";
import Contact from "./Components/Contact/Contact";
import Book from "./Components/Book/Book";
import HostelAmenities from "./Components/HostelAmenities/HostelAmeties";
import DormitoryRoomDes from "./Components/DormitoryRoomDes/DormitoryRoomDes";
import DRoomDesInDetails from "./Components/RoomDesInDetails/DRoomDesInDetails";
import BranchWisePackages from "./Components/BranchWisePackages/BranchWisePackages";
import Login from "./Pages/Login/login";
import OurBranches from "./Components/OurBranches/OurBranches";
import TermsAndCondition from "./Components/TermsAndCondition/TermsAndCondition";


const router = createBrowserRouter([
  {
    path: "/",
    element: <Root />,
    children: [
      {
        path: "/",
        element: <Home />
      },
      {
        path: "/rooms",
        element: <Rooms />
      },
      {
        path: "/amenities",
        element: <HostelAmenities />
      },
      {
        path: "/gallery",
        element: <Gallery />
      },
      {
        path: "/about",
        element: <About />
      },
      {
        path: "/contact",
        element: <Contact />
      },
      {
        path: "/book",
        element: <Book />
      },
      {
        path: "/dormitory-room-des", // Add this route
        element: <DormitoryRoomDes />
      },
      {
        path: "/dormitory-room-des/details", // Add this route
        element: <DRoomDesInDetails />
      },
      {
        path: "/branch-wise-packages",          // last updated route
        element: <BranchWisePackages />
      },
      {
        path: "/login",
        element: <Login /> 
      },

      {
        path: "/about",
        element: <About />
      },
      {
        path: "/our-branches",  // Add this route
        element: <OurBranches />
      },
      {
        path: "/terms-and-condition",
        element: <TermsAndCondition />
      },
    ]
  },
]);

ReactDOM.createRoot(document.getElementById("root")).render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);






// import * as React from "react";
// import * as ReactDOM from "react-dom/client";

// import {
//   createBrowserRouter,
//   RouterProvider,
// } from "react-router-dom";
// import "./index.css";
// import Root from "./Components/Root/Root";
// import Home from "./Components/Home/Home";
// import Navbar from "./Components/NavBar/NavBar";

// const router = createBrowserRouter([
//   {
//     path: "/",
//     element: <Root />,
//     children: [
//       {
//         path: "/",
//         element: <Home />
//       }
//     ]
//   },
// ]);

// ReactDOM.createRoot(document.getElementById("root")).render(
//   <React.StrictMode>
   
//     <RouterProvider router={router} />
     
//   </React.StrictMode>
// );
