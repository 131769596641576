

import { BsFacebook } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
import { RiInstagramFill } from "react-icons/ri";
import { useNavigate } from "react-router-dom";

const Footer = () => {

    const navigate = useNavigate();

    const handleNavigate = () => {
        navigate('/terms-and-condition'); // Path to the TermsAndCondition component
    };

    
    return (
        <div>
            <footer className="bg-black pb-0 mb-0 text-white">
                <div className="footer p-10 text-white md:flex md:justify-between md:items-start">
                    <nav className="mb-6 md:mb-0">
                        <h3 className="footer-title">About Us</h3> 
                        <p>A dormitory is an establishment that provides paid <br /> 
                        lodging on a short-term and long-term basis. <br /> 
                        Facilities provided may range from a modest-quality.
                        </p>
                    </nav> 
                    <nav className="mb-6 md:mb-0">
                        <h6 className="footer-title">Navigation</h6> 
                        <a className="link link-hover">Privacy policy</a> 
                        <a className="link link-hover " onClick={handleNavigate}>
                            <span>Terms And Condition</span>
                        </a>
                    </nav> 
                    <nav className="mb-6 md:mb-0">
                        <h5 className="footer-title">Our Branches:</h5> 
                        <p>
                            <span>Dhanmondi Branch:</span>  <br />
                            251/L, House# 23, Road# 13/A, <br />
                            Dhanmondi, Dhaka <br />
                            Contact: 01974 466 434
                        </p>
                        <p>
                            <span>Uttara Branch:</span>  <br />
                            House# 20, Road# 05, Sector# 05 <br />
                            Uttara, Dhaka, 1230. <br />
                            Contact: 01751 592 841
                        </p>
                        <p>
                            <span>Ashulia Branch:</span>  <br />
                            Khagan Bazar, Birulia, Ashulia, <br />
                            Dhaka,  <br />
                            Contact: 01975 576 484
                        </p>
                    </nav> 
                    <nav className="mb-6 md:mb-0">
                        <h6 className="footer-title">Telephone</h6> 
                        <a className="link link-hover">+88 01975 57 64 84 (Ashulia Male)</a>
                        <a className="link link-hover">+88 01325 05 19 10 (Ashulia Female)</a>
                        <a className="link link-hover">+88 01974 46 64 34 (Dhanmondi)</a>
                        <a className="link link-hover">+88 01711 70 47 29 (Uttara)</a>
                    </nav>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-2 p-10 gap-4">
                    <div className="md:col-span-1">
                        <h2 className="text-xl"></h2>
                    </div>
                    <div className="md:col-span-1">
                        <form>
                            <h6 className="footer-title">Newsletter</h6> 
                            <fieldset className="form-control w-full md:w-80">
                                <label className="label">
                                    <span className="label-text">Enter your email address</span>
                                </label> 
                                <div className="join">
                                    <input type="text" placeholder="username@site.com" className="input w-full md:px-32 input-bordered join-item" /> 
                                    <button className="btn btn-success join-item">Subscribe</button>
                                </div>
                            </fieldset>
                        </form>
                    </div>
                </div>
                <div className="flex flex-col md:flex-row justify-between items-center px-10 py-4 bg-gray-900">
                    <div className="text-center md:text-left">
                        
                        <p>Copyright ©{new Date().getFullYear()} Younic Hostel. All Rights Reserved. Design by Younic Dev.</p>
                    </div>
                    <div className="flex gap-2 mt-4 md:mt-0">
                        <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><BsFacebook /></span>
                        <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><IoLogoYoutube /></span> 
                        <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400"><RiInstagramFill /></span>
                    </div>
                </div>
            </footer>      
        </div>
    );
};

export default Footer;


// className="bg-base-200  pb-0 mb-0









// import { BsFacebook } from "react-icons/bs";
// import { IoLogoYoutube } from "react-icons/io";
// import { RiInstagramFill } from "react-icons/ri";

// const Footer = () => {
//     return (
//         <div>
//             <footer className="bg-black  pb-0 mb-0 text-white">

//             <div className="footer p-10   text-white">
//                 <nav>
//                     <h3 className="footer-title">About Us</h3> 
//                     <p>A dormitory is an establishment that provides paid <br></br> 
//                     lodging on a short-term and long-term basis. <br></br> 
//                      Facilities provided may range from a modest-quality.
//                      </p>
//                 </nav> 
//                 <nav>
                    
//                     <h6 className="footer-title">Navigation</h6> 
//                     <a className="link link-hover">Privacy policy</a> 
//                     <a className="link link-hover">Terms of use</a>

                   
//                 </nav> 
//                 <nav>
//                     <h5 className="footer-title">Our Branches:</h5> 

//                     <p>
//                        <span>Dhanmondi Branch:</span>  <br />
                        
//                             251/L, House# 23, Road# 13/A, <br />
//                                Dhanmondi,Dhaka <br />
//                                Contact: 01974 466 434
                        
//                     </p>

//                     <p>
//                        <span>Uttara Branch:</span>  <br />
                        
//                          House# 20, Road# 05, Sector# 05 <br />
//                                Uttara,Dhaka, 1230. <br />
//                                Contact: 01751 592 841
                        
//                     </p>

//                     <p>
//                        <span>Ashulia Branch:</span>  <br />
                        
//                          Khagan Bazar, Birulia, Ashulia, <br />
//                                Dhaka,  <br />
//                                Contact: 01975 576 484
                        
//                     </p>
                    
//                 </nav> 

//                 <nav>
//                     <h6 className="footer-title">Telephone</h6> 
//                     <a className="link link-hover">+88 01975 57 64 84 (Ashulia)</a> 
//                     <a className="link link-hover">+88 01974 46 64 34 (Dhanmondi)</a>
                    
//                     <a className="link link-hover">+88 01711 70 47 29 (Uttara)</a>
//                 </nav>
 
//             </div>

//             <div className="grid grid-cols-4">
//                 <div className="col-span-2">
//                     <h2 className="pl-10">Read me</h2>
//                 </div>

//                 <div className="col-span-2 -ml-3">
//                 <form>
//     <h6 className="footer-title">Newsletter</h6> 
//                 <fieldset className="form-control w-80">
//                 <label className="label">
//                     <span className="label-text">Enter your email address</span>
//                 </label> 
//                 <div className="join">
//                     <input type="text" placeholder="username@site.com" className="input px-32  input-bordered join-item" /> 
//                     <button className="btn btn-success join-item">Subscribe</button>
//                 </div>
//                 </fieldset>
//         </form>
//                 </div>
                
//          </div>


//         <div className="flex justify-between px-10">
//              <div>
//                 <p>Copyright ©2024 Younic Hostel. All Rights Reserved. Design by Younic Dev.</p>
//              </div>

//              <div className="flex gap-2">
//                 <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><BsFacebook /></span>
//                <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><IoLogoYoutube /></span> 
//                 <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400"><RiInstagramFill /></span>

//              </div>
//        </div>    
    
 
// </footer>      
//         </div>
//     );
// };

// export default Footer;