
import mediaCoverageImg1 from '../../assets/image/OurMediaCoverage/news-paper-prothom-alo.png';
import mediaCoverageImg2 from '../../assets/image/OurMediaCoverage/channel-24.jpeg';
import mediaCoverageImg3 from '../../assets/image/OurMediaCoverage/somoy.jpg';
import mediaCoverageImg4 from '../../assets/image/OurMediaCoverage/dailystar.jpg';

const MediaCoverage = () => {
  return (
    <div className="">
      <h1 className="text-3xl font-bold text-center mb-8">News</h1>
      <div className="div">
        <div className="max-w-4xl mx-auto p-4">
          <div className="flex gap-16 justify-center">
            <div className="flex justify-center">
              <a
                href="https://www.prothomalo.com/business/%E0%A6%89%E0%A6%A6%E0%A7%8D%E0%A6%AF%E0%A7%8B%E0%A6%95%E0%A7%8D%E0%A6%A4%E0%A6%BE/hewl44kmsr"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mediaCoverageImg1}
                  alt="Media Coverage 1"
                  className="rounded-full ring-2 ring-green-500 h-24 w-24"
                />
              </a>

            </div>
              
            <div className="flex justify-center">
              <a
                href="https://www.facebook.com/channel24bd.tv/videos/%E0%A7%AB%E0%A7%AC-%E0%A6%B9%E0%A6%BE%E0%A6%9C%E0%A6%BE%E0%A6%B0-%E0%A6%A5%E0%A7%87%E0%A6%95%E0%A7%87-%E0%A6%B6%E0%A7%81%E0%A6%B0%E0%A7%81-%E0%A6%8F%E0%A6%96%E0%A6%A8-%E0%A6%95%E0%A7%8B%E0%A6%9F%E0%A6%BF-%E0%A6%95%E0%A7%8B%E0%A6%9F%E0%A6%BF-%E0%A6%9F%E0%A6%BE%E0%A6%95%E0%A6%BE%E0%A6%B0-%E0%A6%AC%E0%A7%8D%E0%A6%AF%E0%A6%AC%E0%A6%B8%E0%A6%BE/981091203141153/?mibextid=MOktm1&rdid=YvF5YbFnngaWa5rV"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mediaCoverageImg2}
                  alt="Media Coverage 2"
                  className="rounded-full ring-2 ring-green-300 h-24 w-24"
                />
              </a>
            </div>

            <div className="flex justify-center">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mediaCoverageImg3}
                  alt="Media Coverage 2"
                  className="rounded-full ring-2 ring-green-300 h-24 w-24"
                />
              </a>
            </div>

            <div className="flex justify-center">
              <a
                href="#"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={mediaCoverageImg4}
                  alt="Media Coverage 3"
                  className="rounded-full ring-2 ring-green-300 h-24 w-24"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MediaCoverage;







// import mediaCoverageImg1 from '../../assets/image/OurMediaCoverage/news-paper-prothom-alo.png';
// import mediaCoverageImg2 from '../../assets/image/OurMediaCoverage/somoy.jpg';
// import mediaCoverageImg3 from '../../assets/image/OurMediaCoverage/dailystar.jpg';
// import { Link } from 'react-router-dom';


// const MediaCoverage = () => {
//   return (

//     <div className="">
//         <h1 className="text-3xl font-bold text-center mb-8">News</h1>
        
//       <div className="div">
//               <div className="max-w-4xl  mx-auto p-4">
                
//                  <div className="flex gap-16 justify-center">     {/* space-y-8 */}
//                   <div className="flex justify-center">

//                     <a 
//                            href="/https://www.prothomalo.com"
//                     > 
//                         <img
//                           src={mediaCoverageImg1}
//                           alt="Media Coverage 1"
//                           className="rounded-full ring-2 ring-green-500 h-24 w-24"
//                         />
//                     </a>
                    
//                   </div>
//                   {/* <p className="text-lg text-gray-700 text-center">
//                             Lorem ipsum dolor sit amet, consectetur adipiscing elit. Sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat.
//                           </p> */}
//                   <div className="flex justify-center">
//                     <img
//                       src={mediaCoverageImg2}
//                       alt="Media Coverage 2"
//                       className="rounded-full ring-2 ring-green-300 h-24 w-24"
//                     />
//                   </div>
//                   {/* <p className="text-lg text-gray-700 text-center">
//                             Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//                           </p> */}
//                   <div className="flex justify-center">
//                     <img
//                       src={mediaCoverageImg3}
//                       alt="Media Coverage 2"
//                       className="rounded-full ring-2 ring-green-300 h-24 w-24"
//                     />
//                   </div>
//                   {/* <p className="text-lg text-gray-700 text-center">
//                     Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat non proident, sunt in culpa qui officia deserunt mollit anim id est laborum.
//                   </p> */}
//                 </div>


//               </div>
//       </div>

      

//     </div>

//   );
// };

// export default MediaCoverage;