import * as React from 'react';
import Accordion from '@mui/material/Accordion';
import AccordionActions from '@mui/material/AccordionActions';
import AccordionSummary from '@mui/material/AccordionSummary';
import AccordionDetails from '@mui/material/AccordionDetails';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

import ReviewImg1 from '../../assets/image/Review_image/R1.jpg';
import ReviewImg2 from '../../assets/image/Review_image/R2.jpg';
import ReviewImg3 from '../../assets/image/Review_image/R3.jpg';
import ReviewImg4 from '../../assets/image/Review_image/R4.jpg';
import ReviewImg5 from '../../assets/image/Review_image/R5.jpg';
import ReviewImg6 from '../../assets/image/Review_image/R6.jpg';
import ReviewImg7 from '../../assets/image/Review_image/R7.jpg';
import ReviewImg8 from '../../assets/image/Review_image/R8.jpg';
import ReviewImg9 from '../../assets/image/Review_image/R9.jpg';
import ReviewImg10 from '../../assets/image/Review_image/R10.jpg';
import ReviewImg11 from '../../assets/image/Review_image/R11.jpg';
import ReviewImg12 from '../../assets/image/Review_image/R12.jpg';
import ReviewImg13 from '../../assets/image/Review_image/R13.jpg';
import ReviewImg14 from '../../assets/image/Review_image/R14.jpg';
import ReviewImg15 from '../../assets/image/Review_image/R15.jpg';



export default function AccordionUsage() {
  return (
    <div className='container mx-auto p-4'>
      <h3 className='text-center text-3xl font-bold'>Client Review</h3>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel1-content"
          id="panel1-header"
        >
          <div className='flex'>
            <img src={ReviewImg1} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Ayesha Sultana</span>
            </p>
          </div>

        </AccordionSummary>
        <AccordionDetails>
          আমার প্রয়োজন ছিলো খুব আর্জেন্ট। বিকালে আপুকে কল দিলাম বললো আমরা এরকম তো দেই না,আচ্ছা ৫মিনিটে আপনাকে জানাচ্ছি, কিন্তু ৫মিনিটের আগেই উনি আমাকে জানালেন একটা বেড খালি আছে, আমি চাইলে থাকতে পারবো। যাওয়ার পর ও আপু খুবই আন্তরিকতার সাথে আমাকে ওয়েলকাম করলেন, আমি রোজা রেখেছিলাম, উনি শর্ট টাইমের মধ্যে আমার ইফতারের ব্যবস্থা করে দিলেন। ধন্যবাদ দিলে কম হবে তাই দিলাম না🫣
          ওভারঅল সার্ভিস ইজ এ ওয়ান
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg2} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>সিদ্ধার্থ বর্মন</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          আমি রানী আমি নাচোল চাপাইনবয়াবগঞ্জ জেলা থেকে এসেছি। আমি খুব খুশি যে এখানে আমি যে রুমে থাকি সেখান থেকে সকালটা খুব সুন্দর হয়।আমাদের ম্যানেজার আপু খুব ভালো।ইউনিক হোম বাসার মতই একটি পরিবেশ আমাদের দিয়েছে।
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg3} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Tondra Tun Nesha Oishee</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          One of the best hoste in Uttara. Budget friendly, environment friendly and best in terms of quality. A safe and clean hostel. Recommended❤️ I hope u guys enjoy the place 🌻  it’s too good ☺️
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg4} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Hrithika Jannat Roshny</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          আসসালামু আলাইকুম
          আমি ঋত্বিকা জান্নাত রশ্নি।
          আমি ইউনিক হোম এ নতুন আসছি।ইউনিক হোম এর পরিবেশ,সিকিউরিটি, খাবার,পড়াশুনার সিস্টেম, রুলস রেগুলেশন সব আমার ভালো লেগেছে। ইউনিক হোম অনেক সুযোগ সুবিধা রয়েছে
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg5} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Sayma Islam Mithi</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          হোস্টেল খুব ভালো, জানলা থেকে প্রাকৃতিক পরিবেশ সব অসাধারণ মনোমুগ্ধকর সুন্দর পরিবেশ।  হোস্টেল আরেক ছোট পরিবার‌ .Thank a lot younic home.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg6} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Sarmin Akter</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          I travel to Dhaka every 3-4 weeks. Since my first stay at this hotel, my travels are more bearable. Rooms are nice and clean. Thanks to staff at reception.i am satisfied of all their ​service.keep up your good work.❤️❤️
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg7} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Sajjida Anowar</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          I am Sajjida Anwar Asha. I study in Daffodil International University. The day I got admitted I spent the whole day looking for a good hostel. But there are no hostels around. Then I went to look for the hostel again before the class started. Later I was very tensed because I had class the next day, then I heard about Unique Hostel from my friend Tanisha. He also lives in this hostel. Then I came to visit Unique Hostel with her. After that I was speechless looking at the rooms, every room has a balcony. There was a lot of wind around. Here I don't have to wash my clothes and here fresh vegetables are cooked and served. This hostel is next to Khagan Bazar, so all universities are very close from here. All in all it feels like I'm at home.
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg8} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>মৌরি বিনতে আজাদ</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          checked in,well secured place,will come again if all goes nice and well....
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg9} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Mily Rahman</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          nice hostel....although haven't got any single room...but it's ok
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg10} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Mahmuda Chowdhury</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          আনেক সুন্দর হোস্টেল । সব ধরনের সুবিধা  আছে । খাবারের ব্যবস্হা ও ভাল । আমার খুব ভাল লাগছে।
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg11} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>নব নীতা</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          100% safe and secure
          ...well behave..
          environment is vry cool and friendly
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg12} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Sanjida Sheikh Sumi Begum</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          এখানে থাকার পরিবেশ  খুবই ভালো।চারিদিকে  শব্দ অনেক কম।পরীক্ষার  পড়ার  জন্য উপযোগি  একটা  পরিবেশ  আছে।
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg13} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Sumaiya Binta Shahid</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          one of the best service i have ever experienced.
          food was so testy....
        </AccordionDetails>
      </Accordion>

      <Accordion>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2-content"
          id="panel2-header"
        >

          <div className='flex'>
            <img src={ReviewImg14} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Iffat Herin Nadia</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          The service of this hostel is very good.  I liked the overall system of this hostel. The rooms are very clean and nice decorated  and the behavior of the staff here is very friendly. overall everything i like it very much...💕💕💜💜.
        </AccordionDetails>
      </Accordion>

      <Accordion defaultExpanded>
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel3-content"
          id="panel3-header"
        >

          <div className='flex'>
            <img src={ReviewImg15} alt="" className="w-16 h-16 rounded-full object-cover" />

            <p className="ml-4 flex items-center justify-center">
              <span>Rujina Khan</span>
            </p>
          </div>
        </AccordionSummary>
        <AccordionDetails>
          এই হোস্টেলে আমি ছিলাম এখানের সার্ভিস খুবই ভালো এবং এখানের  রান্না খুব টেস্টি
        </AccordionDetails>
        <AccordionActions>
          {/* <Button>Cancel</Button> */}
          {/* <Button>Agree</Button> */}
        </AccordionActions>
      </Accordion>
    </div>
  );
}
