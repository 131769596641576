import React from 'react';
import OurBranches from '../OurBranches/OurBranches';

const Rooms = () => {
    return (
        <div>
            <OurBranches />
        </div>
    );
};

export default Rooms;