import React from 'react';

const TermsAndCondition = () => {

    const myStyle = {
        border: '1px solid gray',
        padding: '10px',
        borderRadius: '5px'

    };

    return (
        <div className="container mx-auto p-4 " style={myStyle}>

        <div className="flex justify-center">
            <h4 className="font-bold mb-2 text-center bg-green-500 text-white inline-block px-2 py-1 rounded">Terms And Conditions</h4>
        </div>

        <ul className="list-decimal list-inside text-sm text-gray-700 ">
            <li>Rent to be paid between 01st to 05th of every month. From 06th, a fine of tk. 50 per day will be levied. </li>
            <li>Students of institutions affiliated to Unique Homes have to enter the hostel by 07:00 PM and others have to enter the hostel by 10:00 PM. In case of delay due to any reason, application should be made to the authorities.</li>
            <li>Conversing with any male friend while standing in front of the hostel is not allowed. Riding rickshaws/bikes with them is prohibited.</li>
            <li>Playing loud music and talking loudly are not permitted. Room lights should not be switched on after 11 pm. Accessing other rooms is not allowed.</li>
            <li>Consumption of drugs or engaging in activities detrimental to the nation within the hostel premises is strictly prohibited. Bringing in prohibited items is also not allowed, and any such actions will result in legal consequences.</li>
            <li>Upon admission to the hostel, students are required to pay the admission fee and one month's rent in advance.</li>
            <li>Should a resident wish to leave the hostel, a notice period of at least two months is mandatory. Failure to comply will result in the payment of dues for the subsequent month.</li>
            <li>Each hostel resident must provide photocopies of their university or company ID, as well as copies of their parents' national ID cards, along with their own ID or birth certificate, and two passport-sized photographs. If the resident is of a different nationality, a copy of their passport must be provided.</li>
            <li>If a former resident wishes to reapply for accommodation after vacating their seat, they must complete all necessary hostel procedures again.</li>
            <li>Residents are encouraged to report any issues or concerns to the hostel staff during office hours.</li>
            <li>Damage to hostel property will incur repair costs to be borne by the responsible individual.</li>
            <li>Theft of any kind will result in immediate disciplinary action and restitution.</li>
            <li>Residents must sign in and out when entering and leaving the premises.</li>
            <li>Residents are expected to maintain a standard of appropriate attire at all times.</li>
        </ul> 
    </div>
    );
};

export default TermsAndCondition;