

const amenities = [
  { title: "Prayer Room", imgSrc: "/images/prayer.jpg" },
  { title: "24/7 Reception", imgSrc: "/images/reception.jpg" },
  { title: "Kitchen", imgSrc: "/images/kitchen.jpg" },
  { title: "WiFi", imgSrc: "/images/wifi.jpg" },
  { title: "TV", imgSrc: "/images/tv.jpg" },
  { title: "Laundry", imgSrc: "/images/laundry.jpg" },
  { title: "Refrigerators", imgSrc: "/images/refrigerator.png" },
  { title: "Gym", imgSrc: "/images/gym.png" },
  { title: "MeetingRoom", imgSrc: "/images/meetingRoom123.jpg" },
  { title: "RO System Filter", imgSrc: "/images/waterFilter.png" },
  { title: "AC Room", imgSrc: "/images/AC-room.png" },
  { title: "Non AC Room", imgSrc: "/images/non-ac-room.png" },
  { title: "24/7 Survillence", imgSrc: "/images/cc-tv-footage.png" },
  { title: "Generator", imgSrc: "/images/generator.png" },
  { title: "Parent Accomodation", imgSrc: "/images/parent-accomodation.png" },
  { title: "3 Times Quality Food", imgSrc: "/images/quality-food.png" }
];

function HostelAmenities() {
  return (
    <div className="p-10">
      <h1 className="text-3xl font-bold text-center mb-8">Hostel Amenities</h1>
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
        {amenities.map((amenity, index) => (
          <div
            key={index}
            className="rounded-lg overflow-hidden shadow-md bg-white flex flex-col items-center"
          >
            <img
              src={amenity.imgSrc}
              alt={amenity.title}
              // className="w-35 h-15 12 object-cover"
              className="w-18  h-12 object-cover "
            />
            <div className="p-4 text-center">
              <h3 className="text-lg font-semibold">{amenity.title}</h3>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
}

export default HostelAmenities;




// const amenities = [
//     { title: "Prayer Room", imgSrc: "/images/prayer-room.jpg" },
//     { title: "24/7 Reception", imgSrc: "/images/reception.jpg" },
//     { title: "Kitchen", imgSrc: "/images/kitchen.jpg" },
//     { title: "WiFi", imgSrc: "/images/wifi.jpg" },
//     { title: "TV", imgSrc: "/images/tv.jpg" },
//     { title: "Laundry", imgSrc: "/images/laundry.jpg" },
//     { title: "Coffee", imgSrc: "/images/coffee.jpg" },
//     { title: "Elevators", imgSrc: "/images/elevators.jpg" },
//     { title: "Meeting Room", imgSrc: "/images/meeting-room.jpg" },
//     { title: "Washing Service", imgSrc: "/images/washing-service.jpg" },
//     { title: "Refrigerators", imgSrc: "/images/refrigerators.jpg" },
//     { title: "Gym", imgSrc: "/images/gym.jpg" }
//   ];











