
import HostelAmenities from "../HostelAmenities/HostelAmeties";

import OurBranches from "../OurBranches/OurBranches";
import Slider from "../Slider/Slider";
import MediaCoverage from "../MediaCoverage/MediaCoverage";
import YounicRoomSlider from "../YounicRoomSlider/YounicRoomSlider";

import Contact from "../Contact/Contact";
import About from "../About/About";
import AccordionUsage from "../Accordion/AccordionUsage";



const Home = () => {
    return (
        <div >
            
            <Slider/>

            <YounicRoomSlider />

            <OurBranches/>

            <HostelAmenities />

            <MediaCoverage />

            <About />

            <AccordionUsage />

            <Contact />

               
        </div>
    );
};

export default Home;