import React from 'react';
import './About.css';
import { useNavigate } from 'react-router-dom';
const About = () => {

    const navigate = useNavigate(); // Initialize the navigate function

    const handleExploreNow = () => {
        navigate('/our-branches'); // Navigate to the OurBranches component
    };

    return (


        // flex flex-col items-center justify-center min-h-screen bg-white p-6

        // <div className="container mx-auto p-4">

        //     <h3 className='text-center text-3xl font-bold'>About Info</h3>

        //        <p>
        //             যে সকল ব্যাচেলর/ চাকুরীজীবী #ঢাকাতে নিরাপদ ও ঝামেলাহীন কোনো বাসস্থানের খোঁজ করছেন তারা অনায়াসে আমাদের হোস্টেলে থাকতে পারবেন।ব্যাচেলর/ চাকুরীজীবী উভয়ই থাকতে পারেন নিঃসন্দেহে। 📌✈️✈️তাছাড়াও ঢাকার বাইরে থেকে যারা এক্সামের জন্য আসেন তাদের জন্য ৩,৭,১৫ ও ৩০ দিনের প্যাকেজ তো আছেই। তাই সেরা সেবা পেতে আজই যোগাযোগ করুন আমাদের সাথে আর নিশ্চিন্তে বেছে নিন পছন্দের হোস্টেল রুম ।
        //         </p>

        //     <div className="flex flex-col items-center justify-center min-h-screen bg-white  p-6">


        //          <p>
        //          If any bachelors or professionals are looking for a safe and hassle-free accommodation in Dhaka, they can easily stay at our hostel. Both bachelors and professionals are welcome without a doubt. 📌✈️✈️ Additionally, for those coming from outside Dhaka for exams, we offer packages for 3, 7, 15, and 30 days. So, to receive the best service, contact us today and comfortably choose your preferred hostel room.
        //          </p>

        //         <h1 className="text-4xl font-bold text-center gradient-text animate-bounce mb-4">
        //             Younic Dormitory
        //         </h1>



        //         <h5 className="text-lg text-gray-600 text-center animate-slideInUp mb-6">
        //             Best accommodation for bachelors
        //         </h5>


        //         <div className="w-1/2 h-1 bg-gradient-to-r from-green-400 via-orange-400 to-pink-400 rounded-full mb-6 divider-expand"></div>


        //         <button className="px-6 py-3 text-white font-semibold bg-green-400 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300 glow-effect button-hover-effect"
        //                 onClick={handleExploreNow}     
        //         >
        //             Explore Now
        //         </button>
        //     </div>

        // </div>

        <div className="container mx-auto p-4">
            <h3 className="text-center text-3xl font-bold mb-4">About Info</h3>

            {/* <p className="text-center mb-6">
                যে সকল ব্যাচেলর/ চাকুরীজীবী #ঢাকাতে নিরাপদ ও ঝামেলাহীন কোনো বাসস্থানের খোঁজ করছেন তারা অনায়াসে আমাদের হোস্টেলে থাকতে পারবেন।ব্যাচেলর/ চাকুরীজীবী উভয়ই থাকতে পারেন নিঃসন্দেহে। 📌✈️✈️তাছাড়াও ঢাকার বাইরে থেকে যারা এক্সামের জন্য আসেন তাদের জন্য ৩,৭,১৫ ও ৩০ দিনের প্যাকেজ তো আছেই। তাই সেরা সেবা পেতে আজই যোগাযোগ করুন আমাদের সাথে আর নিশ্চিন্তে বেছে নিন পছন্দের হোস্টেল রুম ।
            </p> */}

            <div className="flex flex-col items-center justify-center  bg-white p-4">
                <p className="text-center mb-4">
                    If any bachelors or professionals are looking for a safe and hassle-free accommodation in Dhaka, they can easily stay at our hostel. Both bachelors and professionals are welcome without a doubt. 📌✈️✈️ Additionally, for those coming from outside Dhaka for exams, we offer packages for 3, 7, 15, and 30 days. So, to receive the best service, contact us today and comfortably choose your preferred hostel room.
                </p>

                <h1 className="text-4xl font-bold text-center gradient-text animate-bounce mb-2">
                    Younic Dormitory
                </h1>

                <h5 className="text-lg text-gray-600 text-center animate-slideInUp mb-4">
                    Best accommodation for bachelors
                </h5>

                <div className="w-1/2 h-1 bg-gradient-to-r from-green-400 via-orange-400 to-pink-400 rounded-full mb-6 divider-expand"></div>

                <button className="px-6 py-3 text-white font-semibold bg-green-400 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300 glow-effect button-hover-effect"
                    onClick={handleExploreNow}
                >
                    Explore Now
                </button>
            </div>
        </div>

    );
};

export default About;




//without css design just used inline tailwind

// <div className="flex flex-col items-center justify-center min-h-screen bg-gradient-to-b from-white-100 to-gray-300 p-6">
//     {/* Animated heading */}
//     <h1 className="text-4xl font-bold text-center text-gray-800 animate-bounce mb-4">
//         Younic Dormitory
//     </h1>

//     {/* Subtitle with slide-in animation */}
//     <h5 className="text-lg text-gray-600 text-center animate-slideInUp mb-6">
//         Best accommodation for bachelors
//     </h5>

//     {/* Divider with a gradient */}
//     <div className="w-1/2 h-1 bg-gradient-to-r from-green-400 via-orange-400 to-pink-400 rounded-full mb-6"></div>

//     {/* Call-to-action button */}
//     <button className="px-6 py-3 text-white font-semibold bg-green-400 hover:bg-orange-400 rounded-full shadow-lg transition-transform transform hover:scale-105 focus:outline-none focus:ring-4 focus:ring-green-300">
//         Explore Now
//     </button>
// </div>