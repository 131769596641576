import { Outlet } from "react-router-dom";
import Footer from "../Footer/Footer";
import Navbar from "../NavBar/NavBar";

 

const Root = () => {
    return (
        <div>

            <div className="max-w-[1240px] mx-auto">
                 <Navbar />
            </div>
            
            <Outlet />

            <Footer />
        </div>
    );
};

export default Root;