
import { Link, useNavigate } from "react-router-dom";
import { RiInstagramFill } from "react-icons/ri";
import { BsFacebook } from "react-icons/bs";
import { IoLogoYoutube } from "react-icons/io";
import { FaSearch } from "react-icons/fa";
import logoImage from "../../assets/younic-home.svg";

const Navbar = () => {
    const navigate = useNavigate();

    const handleSignInClick = () => {
        navigate('/login'); // Programmatically navigate to the Login page
    };

    return (
        <div className="mb-16">
            <div className="fixed top-0 left-0 right-0 z-50">
                <div className="bg-base-100 shadow-lg px-4 h-16">
                    <div className="container mx-auto flex items-center justify-between">
                        <div className="navbar-start flex items-center">
                            <div className="dropdown">
                                <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
                                    <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                        <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
                                    </svg>
                                </div>
                                <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">
                                    <li><Link to="/" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">HOME</Link></li>
                                    <li><Link to="/rooms" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">ROOMS</Link></li>
                                    <li><Link to="/amenities" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">AMENITIES</Link></li>
                                    <li><Link to="/gallery" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">GALLERY</Link></li>
                                    <li><Link to="/about" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">ABOUT US</Link></li>
                                    <li><Link to="/contact" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">CONTACT</Link></li>
                                    <li>
                                        <Link to="/contact" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">
                                            <button className="bg-green-400 hover:bg-orange-400">BOOK NOW</button>
                                        </Link>
                                    </li>
                                </ul>
                            </div>
                            <Link to="/"><img src={logoImage} className="w-[140px] h-[60px]" alt="Younic Logo" /></Link>
                        </div>
                        <div className="navbar-center hidden lg:flex">
                            <ul className="menu menu-horizontal px-1 items-center space-x-4">
                                <li><Link to="/" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">HOME</Link></li>
                                <li><Link to="/rooms" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">ROOMS</Link></li>
                                <li><Link to="/amenities" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">AMENITIES</Link></li>
                                <li><Link to="/gallery" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">GALLERY</Link></li>
                                <li><Link to="/about" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">ABOUT US</Link></li>
                                <li><Link to="/contact" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">CONTACT</Link></li>
                                <li>
                                    <Link to="/contact" className="hover:bg-transparent focus:bg-transparent focus:outline-none active:bg-transparent">
                                        <button className="p-2 rounded bg-orange-400 hover:bg-green-400">BOOK NOW</button>
                                    </Link>
                                </li>
                                <li><button className="p-2 rounded bg-orange-400 hover:bg-green-400"
                                            onClick={handleSignInClick}>Sign In
                                    </button>
                                </li>
                            </ul>
                        </div>
                        <div className="navbar-end flex items-center space-x-3">
                            <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400">
                                <a href="https://www.facebook.com/YounicHome" target="_blank" rel="noopener noreferrer">
                                    <BsFacebook />
                                </a>
                            </span>
                            <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400">
                                <a href="https://www.instagram.com/younichome/?igsh=Z3BsbXQ1eTBqNmhu" target="_blank" rel="noopener noreferrer">
                                    <RiInstagramFill />
                                </a>
                            </span>
                            <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400">
                                <a href="https://www.youtube.com/@younichome2023" target="_blank" rel="noopener noreferrer">
                                    <IoLogoYoutube />
                                </a>
                            </span>
                            <span className="p-2 border-2 rounded-full ml-3 bg-orange-400 hover:bg-green-400"><FaSearch /></span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
};

export default Navbar;




//last updated august 22-8-2024



//Lasr updated 14-7-2024









//last updated
// import React, { useState } from "react";
// import { Link } from "react-router-dom";
// import { RiInstagramFill } from "react-icons/ri";
// import { BsFacebook } from "react-icons/bs";
// import { IoLogoYoutube } from "react-icons/io";
// import { FaSearch } from "react-icons/fa";
// import logoImage from "../../assets/younic-home.svg"; 
// import SignIn from "../SignIn/SignIn";

// const Navbar = () => {
//     const [showSignIn, setShowSignIn] = useState(false);

//     return (
//         <div>
//             <div className="navbar bg-base-100 h-4">
//                 <div className="navbar-start">
//                     <div className="dropdown">
//                         <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
//                             <svg xmlns="http://www.w3.org/2000/svg" className="h-5 w-5" fill="none" viewBox="0 0 24 24" stroke="currentColor">
//                                 <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M4 6h16M4 12h8m-8 6h16" />
//                             </svg>
//                         </div>
                        
//                         <ul tabIndex={0} className="menu menu-sm dropdown-content mt-3 z-[1] p-2 shadow bg-base-100 rounded-box w-52">    
//                             <li><Link to="/">HOME</Link></li>
//                             <li><Link to="/rooms">ROOMS</Link></li>
//                             <li><Link to="/amenities">AMENITIES</Link></li>
//                             <li><Link to="/gallery">GALLERY</Link></li>
//                             <li><Link to="/about">ABOUT US</Link></li>
//                             <li><Link to="/contact">CONTACT</Link></li>
//                             <li>
//                                 <Link to="/book">
//                                     <span>
//                                         <button className="p-2 mt-[-6] bg-green-400 hover:bg-orange-400">BOOK NOW</button>
//                                     </span>
//                                 </Link>
//                             </li>
//                         </ul>
//                     </div>
//                     <Link to="/"><img src={logoImage} className="w-[140px] h-[60px]" alt="Younic Logo" /></Link>
//                 </div>
//                 <div className="navbar-center hidden lg:flex">
//                     <ul className="menu menu-horizontal px-1">
//                         <li><Link to="/">HOME</Link></li>
//                         <li><Link to="/rooms">ROOMS</Link></li>
//                         <li><Link to="/amenities">AMENITIES</Link></li>
//                         <li><Link to="/gallery">GALLERY</Link></li>
//                         <li><Link to="/about">ABOUT US</Link></li>
//                         <li><Link to="/contact">CONTACT</Link></li>
//                         <li>
//                             <Link to="/book">
//                                 <button className="p-2 rounded bg-orange-400 hover:bg-green-400">BOOK NOW</button>
//                             </Link>
//                         </li>
//                     </ul>
//                 </div>
//                 <div className="navbar-end">
//                     <button className="p-2 mx-1 rounded bg-orange-400 hover:bg-green-400 text-white" onClick={() => setShowSignIn(true)}>Sign In</button>
//                     <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><BsFacebook /></span>
//                     <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400"><RiInstagramFill /></span>
//                     <span className="p-2 border-2 rounded-full bg-orange-400 hover:bg-green-400" ><IoLogoYoutube /></span>
//                     <span className="p-2 border-2 rounded-full ml-3 bg-orange-400 hover:bg-green-400"><FaSearch /></span>
//                 </div>
//             </div>

//             {showSignIn && (
//                 <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
//                     <div className="bg-white p-5 rounded">
//                         <SignIn />
//                         <button onClick={() => setShowSignIn(false)} className="mt-3 p-2 bg-gray-300 rounded">Close</button>
//                     </div>
//                 </div>
//             )}
//         </div>
//     );
// };

// export default Navbar;



//this more older version code my code
