import React, { useState } from 'react';
import logoImage from "../../assets/younic-logo.jpg";

const GuestForm = () => {
    const [imageFile, setImageFile] = useState(null);

    const handleImageUpload = (event) => {
        const newImage = event.target.files[0];
        // Basic image validation (optional)
        if (!newImage || !newImage.type.match('image/.*')) {
            alert('Please select a valid image file.');
            return;
        }
        setImageFile(newImage);
    };

    return (
        <div className="max-w-2xl mx-auto p-6 bg-white shadow-md rounded-md">
            <div className="flex justify-between items-center mb-6">
                <img src={logoImage} className="w-[180px] h-[120px]" alt="Younic Logo" />
                <h2 className="text-5xl font-bold text-green-500 text-center">Younic Home</h2>
                <div className="flex flex-col items-center">
                    <img
                        id="userImage"
                        src={imageFile ? URL.createObjectURL(imageFile) : ''}
                        alt="Click to upload an image"
                        className="w-[120px] h-[120px] border border-green-500 cursor-pointer"
                        onClick={() => document.getElementById('imageInput').click()}
                    />
                    <input
                        id="imageInput"
                        type="file"
                        accept="image/*"
                        hidden
                        onChange={handleImageUpload}
                    />
                </div>
            </div>

            <div className="flex justify-between items-center mb-6">
                <h2 className="bg-green-500 text-white p-2 rounded">Information</h2>
                <h3 className="bg-green-500 text-white p-2 rounded mx-auto inline-block">Guest Form</h3>
            </div>

            <form>
                <div className="mb-4">
                    <label className="block text-gray-700">Full Name</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                {/* <div className="mb-4">
                    <label className="block text-gray-700">Mobile Number</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded" />

                    <label className="block text-gray-700">National ID/Birth Certificate Number</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded" />

                </div> */}

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">NID/Birth Certificate Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                {/* <div className="mb-4">
                    <label className="block text-gray-700">National ID/Birth Certificate Number</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded" />
                </div> */}

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Passport Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Nationality</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Religion</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>
                
                <div className="mb-4">
                    <label className="block text-gray-700">Address</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Email</label>
                        <input type="email" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    {/* <div className="flex-1">
                        <label className="block text-gray-700">Blood Group</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div> */}

                        <div className="flex-1">
                                <label className="block text-gray-700">Blood Group</label>
                                <select className="w-full p-2 border border-gray-300 rounded focus:outline-none">
                                    <option value="">Select Blood Group</option>
                                    <option value="A+">A+</option>
                                    <option value="A-">A-</option>
                                    <option value="B+">B+</option>
                                    <option value="B-">B-</option>
                                    <option value="AB+">AB+</option>
                                    <option value="AB-">AB-</option>
                                    <option value="O+">O+</option>
                                    <option value="O-">O-</option>
                                </select>
                        </div>
                </div>
                

                <div className="mb-4 flex gap-4">
                    <div className="flex-1">
                        <label className="block text-gray-700">Father's Name</label>
                        <input type="email" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                    <div className="flex-1">
                        <label className="block text-gray-700">Father's Mobile Number</label>
                        <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                    </div>
                </div>

                {/* <div className="mb-4">
                    <label className="block text-gray-700">Father's Name</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded" />
                </div>
                <div className="mb-4">
                    <label className="block text-gray-700">Father's Mobile Number</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded" />
                </div> */}



                <div className="mb-4">
                    <label className="block text-gray-700">Reason For Coming To The Guest House</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>



                <div className="mb-4">
                    <label className="block text-gray-700">How Many Days Want To Stay</label>
                    <input type="text" className="w-full p-2 border border-gray-300 rounded focus:outline-none" />
                </div>

                <div className="mb-4">
                    <label className="block text-gray-700">Are you associated with student of Younic Home ?</label>
                    <div className="flex items-center">
                        <input type="radio" name="coaching" value="yes" className="mr-2" /> Yes
                        <input type="radio" name="coaching" value="no" className="ml-4 mr-2" /> No
                    </div>
                </div>


                <div className="mb-6">
                    <div className="flex justify-center">
                        <h4 className="font-bold mb-2 text-center bg-green-500 text-white inline-block px-2 py-1 rounded">Rules and Regulations</h4>
                    </div>

                    <ol className="list-decimal list-inside text-sm text-gray-700">

                        <li> To reserve a seat, advance payment is required. Failure to comply will result in seat cancellation. </li>
                        <li> Entry to the hostel must be made by 10:00 PM. Any delay requires prior notification to the authorities. </li>
                        <li> Loud music or speaking loudly is not permitted. Room lights should not be switched on after 11:00 PM. Access to other rooms is prohibited. </li>
                        <li> Consumption of drugs, engaging in activities against the state, or bringing prohibited items into the hostel premises is strictly prohibited. Legal action will be taken if such activities are proven. </li>
                        <li> Damage to hostel property must be compensated for repair. </li>
                        <li> Theft will result in immediate legal action and compensation upon proof. </li>
                        <li> Residents must sign in and out upon entry and exit. </li>
                        <li> Any attempt to exert political influence against the rules will result in legal action. </li>
                        <li> Any inappropriate conduct with hostel staff, attempts to intimidate or threaten over the phone, or any behavior that causes fear or discomfort will be dealt with according to the law. At that moment, the individual will be immediately evicted from the premises, along with all belongings, and no refunds will be provided in such circumstances. </li>
                        <li> Any inappropriate behavior, shouting, or loud arguments with roommates or flatmates is not permitted. </li>
                        <li> In cases where an individual suffers physical harm due to personal, familial, social, or any other reasons while residing in the hostel premises, or if an individual attempts or commits suicide, they will be immediately evicted from the hostel. In such instances, neither the individual nor the hostel authorities will be held responsible for any liabilities. </li>
                        <li> The responsibility for retaining payment receipts lies solely with the payer. Failure to produce a receipt may result in non-acceptance of payment. </li>
                        <br />
                        <p>
                            I, <input placeholder="Your Name"
                                class="peer h-full  border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                            <label
                                class="after:content[' '] pointer-events-none absolute left-0  -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Static
                            </label> , hereby agree to abide by all the rules and regulations of the guest house. Any evidence of antisocial or unlawful behavior will be deemed conclusive by the authorities and will result in immediate action.
                        </p>
                    </ol>
                    
                    <br />
                    
                    <div className="signature justify-between flex">
                    <input placeholder="Guest Signatur"
                                class="peer h-full  border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                            <label
                                class="after:content[' '] pointer-events-none absolute left-0  -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Static
                            </label>
                            

                            <input placeholder="Manager Signature"
                                class="peer h-full  border-b border-blue-gray-200 bg-transparent pt-4 pb-1.5 font-sans text-sm font-normal text-blue-gray-700 outline outline-0 transition-all placeholder-shown:border-blue-gray-200 focus:border-gray-900 focus:outline-0 disabled:border-0 disabled:bg-blue-gray-50" />
                            <label
                                class="after:content[' '] pointer-events-none absolute left-0  -top-2.5 flex h-full w-full select-none !overflow-visible truncate text-sm font-normal leading-tight text-gray-500 transition-all after:absolute after:-bottom-2.5 after:block after:w-full after:scale-x-0 after:border-b-2 after:border-gray-500 after:transition-transform after:duration-300 peer-placeholder-shown:leading-tight peer-placeholder-shown:text-blue-gray-500 peer-focus:text-sm peer-focus:leading-tight peer-focus:text-gray-900 peer-focus:after:scale-x-100 peer-focus:after:border-gray-900 peer-disabled:text-transparent peer-disabled:peer-placeholder-shown:text-blue-gray-500">
                                Static
                            </label>
                            
                    </div>
                </div>


                <button type="submit" className="w-full bg-orange-400 hover:bg-green-400 text-white py-2 rounded">Submit</button>
            </form>
        </div>
    );
};

export default GuestForm;