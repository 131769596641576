
 




// import React from 'react';
// import { useLocation, useNavigate } from 'react-router-dom';

// import HostelAmenities from '../HostelAmenities/HostelAmeties';
// import UttaraBrRoomImg from '../../assets/image/U-Album-images/U-album-img2.jpg';
// import DhanmondiBrRoomImg from '../../assets/image/D-Album-images/D-album-img1.jpg';
// import AshuliaBrRoomImg from '../../assets/image/D-Album-images/D-album-img9.jpg';

// const DormitoryRoomDes = () => {
//   const location = useLocation();
//   const branch = location.state?.branch;
//   const navigate = useNavigate(); // Get the navigate function

//   const handleMoreInfoClick1 = () => {
//     navigate('/dormitory-room-des/details1', { state: { branch } });
    
//   };

//   const handleMoreInfoClick2 = () => {
//     navigate('/dormitory-room-des/details2', { state: { branch } });
//   };

//   const handleMoreInfoClick3 = () => {
//     navigate('/dormitory-room-des/details3', { state: { branch } });
//   };

//   return (
//     <div className="container mx-auto p-4">
//       <div className="mb-4">
//         <h1 className="text-2xl font-bold">Find the perfect Dormitory on younic.com</h1>
//         <p className="text-gray-600">From budget-friendly Dormitoris to luxury rooms and everything in between</p>
//         <div className="mt-4 flex border border-orange-500 rounded-lg overflow-hidden">
//           <input type="text" placeholder="Where are you going?" className="p-2 flex-grow focus:outline-none" />
//           <input type="text" placeholder="Check-in - Check-out" className="p-2 flex-grow focus:outline-none" />
//           <input type="text" placeholder="2 adults -  1 room" className="p-2 flex-grow focus:outline-none" />
//           <button className="bg-orange-400 hover:bg-green-400 text-white p-2">Search</button>
//         </div>
//       </div>


//       {/* above code comment code should be place here  */}

     


//         <div className="container mx-auto px-4 py-4">
//           <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//             <div onClick={handleMoreInfoClick1} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//               <div className="relative">
//                 <img
//                   src={DhanmondiBrRoomImg}
//                   alt="Dormitory Room"
//                   className="w-full h-48 object-cover"
//                 />
//                 <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                   ★ 4.5
//                 </div>
//               </div>
//               <div className="p-4">
//                 <h2 className="text-xl font-bold mb-2">Ashulia Dormitory</h2>
//                 <div className="flex justify-between gap-2">
//                   <p className="text-gray-600 mb-4">Address of Ashulia branch</p>
//                   <p className="text-black-700 font-bold">price: 1150</p>
//                 </div>
//                 <div className="flex justify-end">
//                   <button
//                     className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg"
                    
//                   >
//                     More Info
//                   </button>
//                 </div>
//               </div>
//             </div>

//             <div onClick={handleMoreInfoClick2} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//               <div className="relative">
//                 <img
//                   src={AshuliaBrRoomImg}
//                   alt="Dormitory Room"
//                   className="w-full h-48 object-cover"
//                 />
//                 <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                   ★ 4.5
//                 </div>
//               </div>
//               <div className="p-4">
//                 <h2 className="text-xl font-bold mb-2">Dhanmondi Dormitory</h2>
//                 <div className="flex justify-between gap-2">
//                   <p className="text-gray-600 mb-4">Address of Dhanmondi branch</p>
//                   <p className="text-black-700 font-bold">price: 1150</p>
//                 </div>
//                 <div className="flex justify-end">
//                   <button
//                     className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg"
                    
//                   >
//                     More Info
//                   </button>
//                 </div>
//               </div>
//             </div>

//             <div onClick={handleMoreInfoClick3} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//               <div className="relative">
//                 <img
//                   src={UttaraBrRoomImg}
//                   alt="Dormitory Room"
//                   className="w-full h-48 object-cover"
//                 />
//                 <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                   ★ 4.5
//                 </div>
//               </div>
//               <div className="p-4">
//                 <h2 className="text-xl font-bold mb-2">{branch} Dormitory</h2>
//                 <div className="flex justify-between gap-2">
//                   <p className="text-gray-600 mb-4">Address of {branch} branch</p>
//                   <p className="text-black-700 font-bold">price: 1150</p>
//                 </div>
//                 <div className="flex justify-end">
//                   <button
//                     className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg"
                    
//                   >
//                     More Info
//                   </button>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>





//       <div className="div">
//          <HostelAmenities />
//       </div>

//     </div>

    
//   );
// };

// export default DormitoryRoomDes;




import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import HostelAmenities from '../HostelAmenities/HostelAmeties';
import UttaraBrRoomImg from '../../assets/image/U-Album-images/U-album-img2.jpg';
import DhanmondiBrRoomImg from '../../assets/image/D-Album-images/D-album-img1.jpg';
import AshuliaBrRoomImg from '../../assets/image/D-Album-images/D-album-img9.jpg';
import SearchBar from '../Common/SearchBar';

const DormitoryRoomDes = () => {
  const location = useLocation();
  const branch = location.state?.branch;
  const navigate = useNavigate(); // Get the navigate function

  // Scroll to top when the component renders
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleMoreInfoClick = () => {
    navigate('/dormitory-room-des/details', { state: { branch } });
  };

  return (
    <div className="container mx-auto p-4">
      <SearchBar/>

      <div className="container mx-auto px-4 py-4">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
          <div onClick={handleMoreInfoClick} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="relative">
              <img src={DhanmondiBrRoomImg} alt="Dormitory Room" className="w-full h-48 object-cover" />
              <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
                ★ 4.5
              </div>
            </div>
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">Ashulia Dormitory</h2>
              <div className="flex justify-between gap-2">
                <p className="text-gray-600 mb-4">Address of Ashulia branch</p>
                <p className="text-black-700 font-bold">price: 1150</p>
              </div>
              <div className="flex justify-end">
                <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">More Info</button>
              </div>
            </div>
          </div>

          <div onClick={handleMoreInfoClick} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="relative">
              <img src={AshuliaBrRoomImg} alt="Dormitory Room" className="w-full h-48 object-cover" />
              <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
                ★ 4.5
              </div>
            </div>
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">Dhanmondi Dormitory</h2>
              <div className="flex justify-between gap-2">
                <p className="text-gray-600 mb-4">Address of Dhanmondi branch</p>
                <p className="text-black-700 font-bold">price: 1150</p>
              </div>
              <div className="flex justify-end">
                <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">More Info</button>
              </div>
            </div>
          </div>

          <div onClick={handleMoreInfoClick} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
            <div className="relative">
              <img src={UttaraBrRoomImg} alt="Dormitory Room" className="w-full h-48 object-cover" />
              <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
                ★ 4.5
              </div>
            </div>
            <div className="p-4">
              <h2 className="text-xl font-bold mb-2">{branch} Dormitory</h2>
              <div className="flex justify-between gap-2">
                <p className="text-gray-600 mb-4">Address of {branch} branch</p>
                <p className="text-black-700 font-bold">price: 1150</p>
              </div>
              <div className="flex justify-end">
                <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">More Info</button>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className="div">
        <HostelAmenities />
      </div>
    </div>
  );
};

export default DormitoryRoomDes;









  //this code running perfectly well but very optimized code
{/* <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4 justify-around" onClick={handleMoreInfoClick}>
{Array.from({ length: 3 }).map((_, index) => (
  <div key={index} className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
    <div className="relative">
      <img
        src={preRoomimg5}
        alt="Dormitory Room"
        className="w-full h-48 object-cover"
      />
      <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
        ★ 4.5
      </div>
    </div>
    <div className="p-4">
      <h2 className="text-xl font-bold mb-2">{branch} Dormitory</h2>
      <div className="flex justify-between gap-2">
        <p className="text-gray-600 mb-4">Address of {branch} branch</p>
        <p className="text-black-700 font-bold">price: 1150</p>
      </div>
      <div className="flex justify-end">
        <button
          className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg"
          onClick={handleMoreInfoClick}
        >
          More Info
        </button>
      </div>
    </div>
  </div>
))}
</div> */}
   











 // Last Upadated 
// import React from 'react';
// import { useLocation } from 'react-router-dom';
// import preRoomimg5 from '../../assets/image/D-album-images/D-album-img5.jpg';

// const DormitoryRoomDes = () => {
//   const location = useLocation();
//   const branch = location.state?.branch;

//   return (
//     <div className="container mx-auto p-4">
//       <div className="mb-4">
//         <h1 className="text-2xl font-bold">Find the perfect Dormitory on younic.com</h1>
//         <p className="text-gray-600">From budget-friendly Dormitoris to luxury rooms and everything in between</p>
//         <div className="mt-4 flex border border-orange-500 rounded-lg overflow-hidden">
//           <input type="text" placeholder="Where are you going?" className="p-2 flex-grow focus:outline-none" />
//           <input type="text" placeholder="Check-in - Check-out" className="p-2 flex-grow focus:outline-none" />
//           <input type="text" placeholder="2 adults · 0 children · 1 room" className="p-2 flex-grow focus:outline-none" />
//           <button className="bg-orange-400 hover:bg-green-400 text-white p-2">Search</button>
//         </div>
//       </div>

//       <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//         <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//           <div className="relative">
//             <img
//               src={preRoomimg5}
//               alt="Dormitory Room"
//               className="w-full h-48 object-cover"
//             />
//             <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//               ★ 4.5
//             </div>
//           </div>

//           <div className="p-4">
//             <h2 className="text-xl font-bold mb-2">{branch} Dormitory</h2>
//             <div className="flex justify-between gap-2">
//               <p className="text-gray-600 mb-4">Address of {branch} branch</p>
//               <p className="text-black-700 font-bold">price: 1150</p>
//             </div>
//             <div className="flex justify-end">
//               <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">
//                 More Info
//               </button>
//             </div>
//           </div>
//         </div>
//       </div>
//     </div>
//   );
// };

// export default DormitoryRoomDes;





































// import React from 'react';

// import preRoomimg5 from '../../assets/image/D-album-images/D-album-img5.jpg';

// const DormitoryRoomDes = () => {
    
//     return (


//         <div className="container mx-auto p-4">

//             <div className="mb-4">
//                 <h1 className="text-2xl font-bold">Find the perfect Dormitory on younic.com</h1>
//                 <p className="text-gray-600">From budget-friendly Dormitoris to luxury rooms and everything in between</p>
//                 <div className="mt-4 flex border border-orange-500  rounded-lg overflow-hidden">
//                     <input type="text" placeholder="Where are you going?" className="p-2 flex-grow  focus:outline-none" />
//                     <input type="text" placeholder="Check-in - Check-out" className="p-2 flex-grow focus:outline-none" />
//                     <input type="text" placeholder="2 adults · 0 children · 1 room" className="p-2 flex-grow focus:outline-none" />
//                     <button className="bg-orange-400 hover:bg-green-400  text-white p-2">Search</button>
//                 </div>
//             </div>

//             <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 gap-4">
//                 {/* Card 1 */}
//                 <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//                     {/* Card Image Section */}
//                     <div className="relative">
//                         <img
//                             src={preRoomimg5}
//                             alt="Dormitory Room"
//                             className="w-full h-48 object-cover"
//                         />
//                         <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                             ★ 4.5
//                         </div>
//                     </div>

//                     {/* Card Content Section */}
//                     <div className="p-4">
//                         {/* Title Section */}
//                         <h2 className="text-xl font-bold mb-2">Uttara Female  Dormitory</h2>

//                         <div className="flex justify-between gap-2">
//                             {/* Address Section */}
//                             <p className="text-gray-600 mb-4">House# 20, Road# 05, Sector# 05, Uttara, Dhaka </p>

//                             {/* Description Section */}
//                             <p className="text-black-700 font-bold">
//                                 <span>price: 1150</span>
//                             </p>
//                         </div>

//                         {/* More Info Button */}
//                         <div className="flex justify-end">
//                             <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">
//                                 More Info
//                             </button>
//                         </div>

//                     </div>
//                 </div>

//                 {/* Card 2 */}
//                 <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//                     {/* Card Image Section */}
//                     <div className="relative">
//                         <img
//                             src={preRoomimg5}
//                             alt="Dormitory Room"
//                             className="w-full h-48 object-cover"
//                         />
//                         <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                             ★ 4.5
//                         </div>
//                     </div>

//                     {/* Card Content Section */}
//                     <div className="p-4">
//                         {/* Title Section */}
//                         <h2 className="text-xl font-bold mb-2">Ashulia Male Dormitory</h2>

//                         <div className="flex justify-between gap-2">
//                             {/* Address Section */}
//                             <p className="text-gray-600 mb-4">Khagan Bazar, Birulia,  Ashulia Branch, Dhaka</p>

//                             {/* Description Section */}
//                             <p className="text-black-700 font-bold">price: 1150</p>
//                         </div>

//                         {/* More Info Button */}
//                         <div className="flex justify-end">
//                             <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">
//                                 More Info
//                             </button>
//                         </div>

//                     </div>
//                 </div>

//                 {/* Card 3 */}
//                 <div className="max-w-sm mx-auto bg-white shadow-md rounded-lg overflow-hidden">
//                     {/* Card Image Section */}
//                     <div className="relative">
//                         <img
//                             src={preRoomimg5}
//                             alt="Dormitory Room"
//                             className="w-full h-48 object-cover"
//                         />
//                         <div className="absolute top-2 right-2 bg-orange-500 text-white text-sm font-bold px-2 py-1 rounded">
//                             ★ 4.5
//                         </div>
//                     </div>

//                     {/* Card Content Section */}
//                     <div className="p-4">
//                         {/* Title Section */}
//                         <h2 className="text-xl font-bold mb-2">Dhanmondi Female Dormitory</h2>

//                         <div className="flex justify-between gap-2">
//                             {/* Address Section */}
//                             <p className="text-gray-600 mb-4">251/L, House# 23, Road# 13/A, Dhanmondi, Dhaka</p>

//                             {/* Description Section */}
//                             <p className="text-black-700 font-bold">price: 1150</p>
//                         </div>

//                         {/* More Info Button */}
//                         <div className="flex justify-end">
//                             <button className="bg-orange-400 hover:bg-green-400 text-white px-4 py-2 rounded-lg">
//                                 More Info
//                             </button>
//                         </div>

//                     </div>
//                 </div>
//             </div>
//         </div>

//     );
// };

// export default DormitoryRoomDes;



























