// import React from 'react';

// const BranchWisePackages = () => {
//     return (
//         <div>
//             this is branch packages
//         </div>
//     );
// };

// export default BranchWisePackages;




import React from 'react';
import { useNavigate } from 'react-router-dom';
import Branchimg1 from '../../assets/image/BranchWisePackagesImg/U-FemaleBrPk-img.jpeg';
import Branchimg2 from '../../assets/image/BranchWisePackagesImg/A-MaleBrPk-img.jpeg';
import Branchimg3 from '../../assets/image/BranchWisePackagesImg/D-FemalBrPk-img.jpeg';

const BranchWisePackages = () => {
  const navigate = useNavigate();

  const handleMoreInfo = (branch) => {
    navigate('/dormitory-room-des', { state: { branch } });
  };
  

  return (
    <div className="container mx-auto py-12">
      <h1 className="text-3xl font-bold text-center mb-8">Our BranchWise Packages</h1>
      <div className="grid grid-cols-1 md:grid-cols-3 gap-8">
        <div onClick={() => handleMoreInfo('Uttara')} className="bg-white rounded-lg shadow-md">
          <img src={Branchimg1} alt="Branch 1" className="w-full h-55 object-cover rounded-t-lg" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">Uttara Female Dormitory</h2>
            <div className="flex items-center mb-4">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <p>House# 20, Road# 05, Sector# 05, Uttara Branch, Dhaka</p>
            </div>
            <button  className="bg-orange-400 text-white px-4 py-2 rounded-md hover:bg-green-400 focus:outline-none focus:bg-orange-400">More Info</button>
          </div>
        </div>

        <div onClick={() => handleMoreInfo('Ashulia')} className="bg-white rounded-lg shadow-md">
          <img src={Branchimg2} alt="Branch 2" className="w-full h-55 object-cover rounded-t-lg" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">Ashulia Male Branch</h2>
            <div className="flex items-center mb-4">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <p>Khagan Bazar, Birulia, Ashulia, <br /> Dhaka.</p>
            </div>
            <button  className="bg-orange-400 text-white px-4 py-2 rounded-md hover:bg-green-400 focus:outline-none focus:bg-orange-400">More Info</button>
          </div>
        </div>

        <div onClick={() => handleMoreInfo('Dhanmondi')} className="bg-white rounded-lg shadow-md">
          <img src={Branchimg3} alt="Branch 3" className="w-full h-55 object-cover rounded-t-lg" />
          <div className="p-4">
            <h2 className="text-xl font-bold mb-2">Dhanmondhi Female Branch</h2>
            <div className="flex items-center mb-4">
              <svg className="w-6 h-6 mr-2" fill="none" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth="2" d="M5 13l4 4L19 7" />
              </svg>
              <p>251/L, House# 23, Road# 13/A, Dhanmondi, Dhaka</p>
            </div>
            <button  className="bg-orange-400 text-white px-4 py-2 rounded-md hover:bg-green-400 focus:outline-none focus:bg-orange-400">More Info</button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BranchWisePackages;




