

import preRoomimg1 from '../../assets/image/D-Album-images/D-album-img1.jpg';
import preRoomimg2 from '../../assets/image/D-Album-images/D-album-img2.jpg';
import preRoomimg3 from '../../assets/image/D-Album-images/D-album-img3.jpg';
import preRoomimg4 from '../../assets/image/D-Album-images/D-album-img4.jpg';
import preRoomimg5 from '../../assets/image/D-Album-images/D-album-img5.jpg';
import preRoomimg6 from '../../assets/image/D-Album-images/D-album-img6.jpg';
import preRoomimg7 from '../../assets/image/D-Album-images/D-album-img7.jpg';



import * as React from 'react';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import FormControl from '@mui/material/FormControl';
import FormControlLabel from '@mui/material/FormControlLabel';
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import Switch from '@mui/material/Switch';
import AccordionUsage from '../Accordion/AccordionUsage';
import HostelAmenities from '../HostelAmenities/HostelAmeties';
import Map from './Map';

import { Link } from "react-router-dom";
import Contact from '../Contact/Contact';
import SearchBar from '../Common/SearchBar';



const rooms = [
    { type: 'Single Room', beds: '1 bed', guests: 2 },
    { type: 'Shared Room', beds: '2 bed', guests: 4 },
    { type: 'Shared Room', beds: '1 bunker bed and 2 single bed', guests: 5 },
    { type: 'Premium Room', beds: '1  bed', guests: 2 },
    { type: 'Single Room', beds: '2 twin beds', guests: 2 },
    { type: 'Shared Room', beds: '3 Beds', guests: 3 },
];




const DRoomDesInDetails = () => {

    const [open, setOpen] = React.useState(false);
    const [fullWidth, setFullWidth] = React.useState(true);
    const [maxWidth, setMaxWidth] = React.useState('sm');

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleMaxWidthChange = (event) => {
        setMaxWidth(
            // @ts-expect-error autofill of arbitrary value is not handled.
            event.target.value,
        );
    };

    const handleFullWidthChange = (event) => {
        setFullWidth(event.target.checked);
    };


    const myStyle = {
        border: '1px solid gray',
        padding: '10px',
        borderRadius: '5px'

    };

    return (
        <div className='p-12 '>
            <SearchBar />

            <React.Fragment>
                <div class="container mx-auto p-4">

                    <div className="flex flex-col md:flex-row items-center justify-between  mx-auto space-y-3 md:space-y-0">
                        <div onClick={handleClickOpen} className=''>
                            <div className='grid grid-cols-6'>
                                <div className='col-span-2' >
                                    <img className='w-full rounded-md m-1 p-1' src={preRoomimg1} alt="s" />
                                    <img className='w-full rounded m-1 p-1' src={preRoomimg1} alt="" />
                                </div>

                                <img className='w-full rounded m-1 p-1 col-span-4 ' src={preRoomimg1} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg2} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg3} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg4} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg5} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg6} alt="" />
                                <img className='w-full aspect-square m-1 p-1 rounded' src={preRoomimg7} alt="" />
                            </div>
                        </div>

                        <div className='p-4'>
                            <p className='font-bold ml-2'>Address: Khagan Bazar, Birulia, Ashulia, Dhaka.</p>
                            <Map />
                        </div>

                    </div>

                    <div className="flex justify-center items-center pt-5">
                        <p className="justify-center">
                            If any bachelors or professionals are looking for a safe and hassle-free accommodation in Dhaka, they can easily stay at our hostel. Both bachelors and professionals are welcome without a doubt. 📌✈️✈️ Additionally, for those coming from outside Dhaka for exams, we offer packages for 3, 7, 15, and 30 days. So, to receive the best service, contact us today and comfortably choose your preferred hostel room.
                            <br />
                            <br />
                            যে সকল ব্যাচেলর/ চাকুরীজীবী #ঢাকাতে নিরাপদ ও ঝামেলাহীন কোনো বাসস্থানের খোঁজ করছেন তারা অনায়াসে আমাদের হোস্টেলে থাকতে পারবেন।ব্যাচেলর/ চাকুরীজীবী উভয়ই থাকতে পারেন নিঃসন্দেহে। 📌✈️✈️তাছাড়াও ঢাকার বাইরে থেকে যারা এক্সামের জন্য আসেন তাদের জন্য ৩,৭,১৫ ও ৩০ দিনের প্যাকেজ তো আছেই। তাই সেরা সেবা পেতে আজই যোগাযোগ করুন আমাদের সাথে আর নিশ্চিন্তে বেছে নিন পছন্দের হোস্টেল রুম ।
                        </p>
                    </div>

                    <div className="div">
                        <HostelAmenities />
                    </div>


                    <Dialog
                        className="w-full max-w-none"
                        open={open}
                        onClose={handleClose}
                        PaperProps={{
                            style: {
                                width: '100%',
                                maxWidth: 'none', // This ensures that the dialog takes the full width.
                                minHeight: '80%',  // You can adjust the height as per your requirement.
                                maxHeight: '80%',  // You can adjust the height as per your requirement.
                            },
                        }}
                    >
                        <DialogTitle>Images of Younic Dormitories</DialogTitle>
                        <DialogContent className='w-full'>
                            <DialogContentText >
                                <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-4">
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg1} alt="Room 1" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg2} alt="Room 2" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg3} alt="Room 3" />

                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg1} alt="Room 1" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg2} alt="Room 2" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg3} alt="Room 3" />

                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg1} alt="Room 1" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg2} alt="Room 2" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg3} alt="Room 3" />

                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg1} alt="Room 1" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg2} alt="Room 2" />
                                    <img className="w-full h-[180px] object-cover m-1" src={preRoomimg3} alt="Room 3" />
                                </div>
                            </DialogContentText>


                        </DialogContent>
                        <DialogActions>
                            <Button onClick={handleClose}>Close</Button>
                        </DialogActions>
                    </Dialog>

                </div>
            </React.Fragment>


            <Contact />

            {/* Accordion Section */}
            <AccordionUsage />

            <br />

            <div className="container mx-auto p-4 " style={myStyle}>

                <div className="flex justify-center">
                    <h4 className="font-bold mb-2 text-center bg-green-500 text-white inline-block px-2 py-1 rounded">Rules and Regulations</h4>
                </div>

                <ul className="list-decimal list-inside text-sm text-gray-700 ">
                    <li>Rent to be paid between 01st to 05th of every month. From 06th, a fine of tk. 50 per day will be levied. </li>
                    <li>Students of institutions affiliated to Unique Homes have to enter the hostel by 07:00 PM and others have to enter the hostel by 10:00 PM. In case of delay due to any reason, application should be made to the authorities.</li>
                    <li>Conversing with any male friend while standing in front of the hostel is not allowed. Riding rickshaws/bikes with them is prohibited.</li>
                    <li>Playing loud music and talking loudly are not permitted. Room lights should not be switched on after 11 pm. Accessing other rooms is not allowed.</li>
                    <li>Consumption of drugs or engaging in activities detrimental to the nation within the hostel premises is strictly prohibited. Bringing in prohibited items is also not allowed, and any such actions will result in legal consequences.</li>
                    <li>Upon admission to the hostel, students are required to pay the admission fee and one month's rent in advance.</li>
                    <li>Should a resident wish to leave the hostel, a notice period of at least two months is mandatory. Failure to comply will result in the payment of dues for the subsequent month.</li>
                    <li>Each hostel resident must provide photocopies of their university or company ID, as well as copies of their parents' national ID cards, along with their own ID or birth certificate, and two passport-sized photographs. If the resident is of a different nationality, a copy of their passport must be provided.</li>
                    <li>If a former resident wishes to reapply for accommodation after vacating their seat, they must complete all necessary hostel procedures again.</li>
                    <li>Residents are encouraged to report any issues or concerns to the hostel staff during office hours.</li>
                    <li>Damage to hostel property will incur repair costs to be borne by the responsible individual.</li>
                    <li>Theft of any kind will result in immediate disciplinary action and restitution.</li>
                    <li>Residents must sign in and out when entering and leaving the premises.</li>
                    <li>Residents are expected to maintain a standard of appropriate attire at all times.</li>
                </ul>
            </div>

            <br />
            <div className="">
                <Link to="/contact">
                    <button className="p-2 w-full rounded bg-orange-400 hover:bg-green-400">BOOK NOW</button>
                </Link>
            </div>

        </div>
    );
};

export default DRoomDesInDetails;