

import * as React from 'react';
import Box from '@mui/material/Box';
import Paper from '@mui/material/Paper';
import Masonry from '@mui/lab/Masonry';
import { styled } from '@mui/material/styles';

import albumImg1 from '../../assets/image/A-Album-images/A-album-img1.jpg';
import albumImg2 from '../../assets/image/A-Album-images/A-album-img2.jpg';
import albumImg3 from '../../assets/image/A-Album-images/A-album-img3.jpg';
import albumImg4 from '../../assets/image/A-Album-images/A-album-img4.jpg';
import albumImg5 from '../../assets/image/A-Album-images/A-album-img5.jpg';
import albumImg6 from '../../assets/image/U-Album-images/U-album-img1.jpg';
import albumImg7 from '../../assets/image/U-Album-images/U-album-img2.jpg';
import albumImg8 from '../../assets/image/U-Album-images/U-album-img3.jpg';
import albumImg9 from '../../assets/image/U-Album-images/U-album-img4.jpg';
import albumImg10 from '../../assets/image/U-Album-images/U-album-img5.jpg';
import albumImg11 from '../../assets/image/U-Album-images/U-album-img6.jpg';
import albumImg12 from '../../assets/image/U-Album-images/U-album-img7.jpg';
import albumImg13 from '../../assets/image/D-Album-images/D-album-img1.jpg';
import albumImg14 from '../../assets/image/D-Album-images/D-album-img2.jpg';
import albumImg15 from '../../assets/image/D-Album-images/D-album-img3.jpg';
import albumImg16 from '../../assets/image/D-Album-images/D-album-img4.jpg';
import albumImg17 from '../../assets/image/D-Album-images/D-album-img5.jpg';

const Gallery = () => {
    const Label = styled(Paper)(({ theme }) => ({
        backgroundColor: theme.palette.mode === 'dark' ? '#1A2027' : '#fff',
        ...theme.typography.body2,
        padding: theme.spacing(0.5),
        textAlign: 'center',
        color: theme.palette.text.secondary,
        borderBottomLeftRadius: 0,
        borderBottomRightRadius: 0,
    }));

    return (
        <div className="flex justify-center items-center min-h-screen m-8 p-8">
            <Box sx={{ width: 1050, minHeight: 500 }} >
                <Masonry columns={3} spacing={3}>
                    {itemData.map((item, index) => (
                        <div key={index}>
                            <Label>{index + 1}</Label>
                            <img
                                srcSet={`${item.img}?w=162&auto=format&dpr=2 2x`}
                                src={`${item.img}?w=162&auto=format`}
                                alt={item.title}
                                loading="lazy"
                                style={{
                                    borderBottomLeftRadius: 4,
                                    borderBottomRightRadius: 4,
                                    display: 'block',
                                    width: '100%',
                                }}
                            />
                        </div>
                    ))}
                </Masonry>
            </Box>
        </div>
    );
};

export default Gallery;

const itemData = [
    { img: albumImg1, title: 'A-br' },
    { img: albumImg2, title: 'Snacks' },
    { img: albumImg3, title: 'Mushrooms' },
    { img: albumImg4, title: 'Tower' },
    { img: albumImg5, title: 'Sea star' },
    { img: albumImg6, title: 'Honey' },
    { img: albumImg7, title: 'Basketball' },
    { img: albumImg8, title: 'Breakfast' },
    { img: albumImg9, title: 'Tree' },
    { img: albumImg10, title: 'Burger' },
    { img: albumImg11, title: 'Camera' },
    { img: albumImg12, title: 'Coffee' },
    { img: albumImg13, title: 'Camping Car' },
    { img: albumImg14, title: 'Hats' },
    { img: albumImg15, title: 'Tomato basil' },
    { img: albumImg16, title: 'Mountain' },
    { img: albumImg17, title: 'Bike' },
];






{/* <URoomDesInDetails />
<br />
<ARoomDesInDetails />
<br />
<DRoomDesInDetails /> */}





