

import React, { useState } from 'react';
import axios from 'axios';

const Contact = () => {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    subject: '',
    message: ''
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await axios.post('http://localhost:5000/submit-form', formData);
      alert(response.data.message);
    } catch (error) {
      console.error('Error submitting form:', error);
    }
  };

  return (
    <div className="container mx-auto p-4">
      <h3 className='text-center text-3xl font-bold'>Contact Info</h3>
      <br />
      <div className="flex flex-col md:flex-row justify-between">
        <div className="w-full md:w-1/2 p-4">
          <form onSubmit={handleSubmit} className="space-y-4">
            <div>
              <label className="block text-gray-700">Your Name <span className='text-red-500'>*</span></label>
              <input
                type="text"
                name="name"
                value={formData.name}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-green-500"
                placeholder="Your Name"
              />
            </div>

            <div>
              <label className="block text-gray-700">Your Phone No <span className='text-red-500'>*</span></label>
              <input
                type="text"
                name="phone"
                value={formData.phone}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-green-500"
                placeholder="Your Phone number"
              />
            </div>

            <div>
              <label className="block text-gray-700">Your Email <span className='text-red-500'>*</span></label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-green-500"
                placeholder="Your Email"
              />
            </div>
            <div>
              <label className="block text-gray-700">Subject</label>
              <input
                type="text"
                name="subject"
                value={formData.subject}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-green-500"
                placeholder="Subject"
              />
            </div>
            <div>
              <label className="block text-gray-700">Message </label>
              <textarea
                name="message"
                value={formData.message}
                onChange={handleChange}
                className="w-full border border-gray-300 p-2 rounded-md focus:outline-none focus:border-green-500"
                placeholder="Message"
                rows="5"
              ></textarea>
            </div>
            <button
              type="submit"
              className="w-full p-3 rounded bg-orange-400 hover:bg-green-400"
            >
              Submit
            </button>
          </form>
        </div>
        {/* Contact Info Section */}
        <div className="w-full md:w-1/2 p-4 mt-8 ml-32 md:mt-0">
          <div className="space-y-4">
            <div>
              <h2 className="text-gray-700 font-bold">EMAIL</h2>
              <p className="text-orange-500">info@younichostel.com</p>
            </div>
            <div>
              <h2 className="text-gray-700  font-bold">PHONE</h2>

              <p className="text-orange-500">
                            <span className=' font-bold'>Dhanmondi Branch:</span>  <br />
                            251/L, House# 23, Road# 13/A, <br />
                            Dhanmondi, Dhaka <br />
                            Contact: 01974 466 434
                        </p>
                        <p className="text-orange-500">
                            <span className=' font-bold'>Uttara Branch:</span>  <br />
                            House# 20, Road# 05, Sector# 05 <br />
                            Uttara, Dhaka, 1230. <br />
                            Contact: 01751 592 841
                        </p>
                        <p className="text-orange-500">
                            <span className=' font-bold'>Ashulia Branch:</span>  <br />
                            Khagan Bazar, Birulia, Ashulia, <br />
                            Dhaka,  <br />
                            Contact: 01975 576 484
                        </p>

              
            </div>

            <div >
            <h2 className="text-gray-700 font-bold">Call Center</h2>

            <p className="text-orange-500">+88 01325 05 19 15 </p>
                  

            </div>

            <div>
              <h2 className="text-orange-500">ADDRESS</h2>
              <p className="text-orange-500">House# 20, Road# 05, Sector# 05, Uttara.</p>
            </div>
          </div>
        </div>

      </div>
    </div>
  );
};

export default Contact;



